import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { clearStorage, getToken } from "../../../utils/storage";
import { removeToken } from "../userReducer";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_API}/`,
  prepareHeaders: (headers, { endpoint, getState }) => {
    const token = getToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    if (endpoint == "generateLicense") {
      const state: any = getState();
      const licenseString = state.license.licenseString;
      headers.set("encrypted-string", licenseString);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  let resp: any = result?.error?.data;
  if (resp?.message === "Invalid or expired token") {
    clearStorage();
    window.location.href = "/login";
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    "GetAllSatpasDeviceList",
    "GetAdminManagementList",
    "GetLicenseServerList",
    "GenerateLicense",
    "GetLogEventsList",
    "GetLogResultList",
    "submitLogin",
    "CreateSatpasDeviceList",
    "ApprovalDevice",
    "CreateAdminManagement",
    "UpdateAdminManagement",
    "GetDashboardHeader",
    "GetLogResultBlob",
    "GetDashboardBar",
    "GetDashboardBarFilter",
    "GetRoleList",
    "GetAdminUserDetail",
    "DeleteAdminManagement",
    "UpdatePassword",
    "GetDashboardGrid",
    "GetProfileDetail",
    "UpdateProfilePassword",
    "GetAdminList",
    "GetAdminDetail",
    "CreateAdmin",
    "UpdateAdmin",
    "ChangePasswordAdmin",
    "DeleteAdmin",
    "GetHistorySigningList",
    "GetSignerList",
    "CreateSigner",
    "GetSignerDetail",
    "UpdateSigner",
    "GenerateUserKeyAndCertificate",
    "RekeyCertificate",
    "RenewCertificate",
    "DeleteSigner",
    "SetSigner",
    "GetEventLogs",
    "GetCertConfig",
    "GetSignParam",
    "GetCertConfigDetail",
    "UpdateCertConfig",
    "CreateIssuer",
    "UpdateSignParams",
    "DownloadIssuer",
    "SetSignerIssuer",
    "GetIssuerList",
    "DeleteIssuer",
    "GetEventLogsDetails",
  ],
});

export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
