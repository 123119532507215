import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { IOpenMainProps } from '../../../interfaces/IUtils';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { clearStorage } from '../../../utils/storage';
import { useAppDispatch } from '../../../store';
import { removeToken } from '../../../store/feature/userReducer';

const drawerWidth = 320;
const Navbar = ({ open, setOpen }: IOpenMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const settings = ['Profile', 'Change Password', 'Logout'];
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [offset, setOffset] = useState(0);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { pathname } = useLocation();
  const param = useParams();
  const breadcrumb: any = [{ label: 'Dashboard', href: '/' }];

  // REGION: BREADCRUMB ADMIN
  if (pathname === '/admin') {
    breadcrumb.push({ label: 'Admin', href: '/admin' });
  } else if (pathname === '/admin/create') {
    breadcrumb.push({ label: 'Admin', href: '/admin' });
    breadcrumb.push({ label: 'Create Admin ', href: '/admin/create' });
  } else if (pathname === `/admin/edit/${param.id}`) {
    breadcrumb.push({ label: 'Admin', href: '/admin' });
    breadcrumb.push({ label: 'Edit Admin ', href: `/admin/edit/${param.id}` });
  } else if (pathname === `/admin/change-password/${param.id}`) {
    breadcrumb.push({ label: 'Admin', href: '/admin' });
    breadcrumb.push({ label: 'Change Password Admin ', href: `/admin/change-password/${param.id}` });
  }

  // REGION: BREADCRUMB SIGNER
  else if (pathname === '/signer') {
    breadcrumb.push({ label: 'Signer', href: '/signer' });
  } else if (pathname === '/signer/create') {
    breadcrumb.push({ label: 'Signer', href: '/signer' });
    breadcrumb.push({ label: 'Create Signer ', href: '/signer/create' });
  } else if (pathname === `/signer/edit/${param.id}`) {
    breadcrumb.push({ label: 'Signer', href: '/signer' });
    breadcrumb.push({ label: 'Edit Signer ', href: `/signer/edit/${param.id}` });
  }

  // REGION: BREADCRUMB ISSUER
  else if (pathname === '/issuer') {
    breadcrumb.push({ label: 'Issuer', href: '/issuer' });
  } else if (pathname === '/issuer/add') {
    breadcrumb.push({ label: 'Issuer', href: '/issuer' });
    breadcrumb.push({ label: 'Add Issuer ', href: '/issuer/add' });
  }

  // REGION: BREADCRUMB CERTIFICATE CONFIGURATION
  else if (pathname === '/certificate-configuration') {
    breadcrumb.push({ label: 'Certificate Configuration', href: '/certificate-configuration' });
  } else if (pathname === `/certificate-configuration/edit/${param.id}`) {
    breadcrumb.push({ label: 'Certificate Configuration', href: '/certificate-configuration' });
    breadcrumb.push({ label: 'Edit Certificate Configuration ', href: `/certificate-configuration/edit/${param.id}` });
  }

   // REGION: BREADCRUMB SIGNING PARAMETER
   else if (pathname === '/signing-parameter') {
    breadcrumb.push({ label: 'Signing Parameter', href: '/signing-parameter' });
  } else if (pathname === `/signing-parameter/edit/${param.id}`) {
    breadcrumb.push({ label: 'Signing Parameter', href: '/signing-parameter' });
    breadcrumb.push({ label: 'Edit Signing Parameter ', href: `/signing-parameter/edit/${param.id}` });
  }

  // REGION: BREADCRUMB EVENT LOGS
  else if (pathname === '/event-logs') {
    breadcrumb.push({ label: 'Event Logs', href: '/event-logs' });
  }

  // REGION: BREADCRUMB HISTORY SIGNING
  else if (pathname === '/history-signing') {
    breadcrumb.push({ label: 'History Signing', href: '/history-signing' });
  }

  // REGION: BREADCRUMB PROFILE
  else if (pathname === '/profile') {
    breadcrumb.push({ label: 'Profile', href: '/profile' });
  }

  // REGION: BREADCRUMB CHANGE PASSWORD
  else if (pathname === '/change-password') {
    breadcrumb.push({ label: 'Change Password', href: '/change-password' });
  }

  useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButton = (setting: any) => {
    setAnchorElUser(null);
    if (setting === 'Logout') {
      dispatch(removeToken());
      navigate('/login', { replace: true });
      clearStorage();
    } else if (setting === 'Profile') {
      navigate('/profile');
    } else if (setting === 'Change Password') {
      navigate('/change-password');
    }
  };

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: 'flex',
        background: 'transparent',
        // background: 'yellow',
        boxShadow: 'none',
        // backgroundColor: '#B3c100',
        alignItems: 'space-between',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar sx={{ borderRadius: 'none', paddingLeft: 0 }} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 3.7,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
        
        {
          offset <= 30 &&
          <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            backgroundColor:'transparent',
            flex:1,
            marginTop:'12px',
            cursor: 'pointer'
            }}
          >
            {breadcrumb.map((item: any, index: Number) => {
              return index != breadcrumb.length-1 ? (
                <Link
                  key={item.label}
                  onClick={() => navigate(item.href)}
                  underline="hover">
                  {item.label}
                </Link>
              ) : (
                <Typography color="textPrimary">
                  {item.label}
                </Typography>
              )
            })}
          </Breadcrumbs>
        }

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => handleButton(setting)}>
                <Typography sx={{ padding: '8px' }} variant='body1'>{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>

        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default Navbar;
