import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";

export const signerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSignerList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/docsign-users`,
          params: params
        };
      },
      providesTags: ["GetSignerList"],
    }),
    getSignerDetail: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/docsign-user/details/${id}`,
        method: "GET"
      }),
      invalidatesTags: ['GetSignerDetail']
    }),
    createSigner: builder.mutation<any, any>({
      query: (body) => ({
        url: "/docsign-user",
        method: "POST",
        body
      }),
      invalidatesTags: ['CreateSigner', 'GetSignerList']
    }),
    updateSigner: builder.mutation<any, any>({
      query: ({body, id}) => ({
        url: `/docsign-user/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdateSigner', 'GetSignerList']
    }),
    generateUserKeyAndCertificate: builder.mutation<any, any>({
      query: (id) => ({
        url: `/gen_online_user_key_and_cert/${id}`,
        method: "POST"
      }),
      invalidatesTags: ['GenerateUserKeyAndCertificate', 'GetSignerList']
    }),
    rekeyCertificate: builder.mutation<any, any>({
      query: (id) => ({
        url: `/gen_online_user_key_and_cert/${id}`,
        method: "POST"
      }),
      invalidatesTags: ['RekeyCertificate', 'GetSignerList']
    }),
    renewCertificate: builder.mutation<any, any>({
      query: (id) => ({
        url: `/renew_certificate/${id}`,
        method: "POST"
      }),
      invalidatesTags: ['RenewCertificate', 'GetSignerList']
    }),
    deleteSigner: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/docsign-user/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ['DeleteSigner', 'GetSignerList']
    }),
    setSigner: builder.mutation<any, any>({
      query: (id) => ({
        url: `/docsign-user-status/${id}`,
        method: "PUT"
      }),
      invalidatesTags: ['SetSigner', 'GetSignerList']
    }),
  }),
});

export const {
  useGetSignerListQuery,
  useGetSignerDetailMutation,
  useCreateSignerMutation,
  useUpdateSignerMutation,
  useGenerateUserKeyAndCertificateMutation,
  useRekeyCertificateMutation,
  useRenewCertificateMutation,
  useDeleteSignerMutation,
  useSetSignerMutation,
} = signerApiSlice;
