import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import LogEventsSearch from '../../components/molecules/logEvents/LogEventsSearch';
import LogEventsTable from '../../components/molecules/logEvents/LogEventsTable';
import { useGetLogEventsListQuery } from '../../store/feature/service/logEventsApiSlice';

const LogEvents = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetLogEventsListQuery(params);
  
  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Log Events
        </Typography>
        <LogEventsSearch params={params} setParams={setParams} />
        <LogEventsTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default LogEvents;
