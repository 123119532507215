//
import { apiSlice } from "../api/apiSlice";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getdashboardHeader: builder.query<any, any>({
      query: () => {
        return {
          url: `/dashboard/header`,
        };
      },
      providesTags: ["GetDashboardHeader"],
    }),
    getDashboardBar: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/dashboard/body`,
          params: params,
        };
      },
      providesTags: ["GetDashboardBar"],
    }),
    getDashboardBarFilter: builder.query<any, any>({
        query: (params) => {
          return {
            url: `/dashboard/body/details`,
            params: params,
          };
        },
        providesTags: ["GetDashboardBarFilter"],
      }),
      getDashboardGrid: builder.query<any, any>({
        query: () => {
          return {
            url: `/dashboard/body/grid`
          };
        },
        providesTags: ["GetDashboardGrid"],
      })
  }),
});

export const { useGetdashboardHeaderQuery, useGetDashboardBarQuery, useGetDashboardBarFilterQuery, useGetDashboardGridQuery } = dashboardApiSlice;
