import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { signerCreate, signerUpdate } from "../../utils/validation/signer";
import Swal from "sweetalert2";
import {
  useCreateSignerMutation,
  useGetSignerDetailMutation,
  useUpdateSignerMutation
} from "../../store/feature/service/signerApiSlice";

const SignerField = () => {
  
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const { pathname } = location;
  const edit = pathname.includes("edit");
  const [createSigner, {
    data: createPayload,
    isLoading : createLoading,
    isSuccess: createSuccess,
    isError: createError,
  }] = useCreateSignerMutation();
  const [updateSigner, {
    data: updatePayload,
    isLoading : updateLoading,
    isSuccess: updateSuccess,
    isError: updateError,
  }] = useUpdateSignerMutation();
  const [getSignerDetail, {
    data: detailPayload,
    isLoading : detailLoading,
    isSuccess: detailSuccess,
    isError: detailError,
  }] = useGetSignerDetailMutation();

  const optPrefixSelect = [
    {
      label: "Mr.",
      value: "M",
    },
    {
      label: "Mrs.",
      value: "F",
    },
  ];

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
    signerName: string;
    prefix: string;
    ktp: string;
    phoneNumber: string;
    creationDate: string;
    signerKeyName: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(!edit ? signerCreate : signerUpdate),
    defaultValues: {
      email: "",
      signerName: "",
      prefix: "",
      ktp: "",
      phoneNumber: "",
      creationDate: "",
      signerKeyName: ""
    },
  });

  useEffect(() => {
    if (edit) {
      getSignerDetail({ id: userUid.id })
      reset({
        email: "",
        signerName: "",
        prefix: "",
        ktp: "",
        phoneNumber: "",
        creationDate: "",
        signerKeyName: ""
      });
    } else {
      reset({
        prefix: "M",
      })
    }
  }, []);

  useEffect(() => {
    if (edit && detailSuccess) {
      const {
        ds_usr_email,
        ds_usr_name,
        ds_usr_gender,
        ds_usr_ektpnumber,
        ds_usr_phonenumber,
        ds_usr_creationdate,
        ds_usr_onlusrkeyname,
      } = detailPayload.data
      reset({
        email: ds_usr_email,
        signerName: ds_usr_name,
        prefix: ds_usr_gender,
        ktp: ds_usr_ektpnumber,
        phoneNumber: ds_usr_phonenumber,
        creationDate: ds_usr_creationdate,
        signerKeyName: ds_usr_onlusrkeyname,
      });
    }
  }, [detailSuccess]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    const { signerName, email, phoneNumber, prefix, ktp } = e;
    try {
      if (edit) {
        const params = {
          ds_usr_ektpnumber: ktp,
          ds_usr_phonenumber: phoneNumber,
          // ds_usr_selfiepict: [0],
          // ds_usr_sign_status: "string"
        }
        await updateSigner({ body: params, id: userUid.id });
      } else {
        const params = {
          ds_company_id: 1,
          ds_usr_ektpnumber: ktp,
          ds_usr_email: email,
          ds_usr_gender: prefix,
          ds_usr_name: signerName,
          ds_usr_phonenumber: phoneNumber
        }
        await createSigner(params);
      }
    } catch (err) {
      console.log("err ===> ", err);
    }
  };

  if (createSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Create Signer",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Create Signer",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/signer");
    });
  }

  if (createError) {
    Swal.fire({
      icon: "error",
      title: "Error Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Create Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Signer",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Signer",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/signer");
    });
  }

  if (updateError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={detailLoading}
      isLoadingEvent={createLoading || updateLoading}
      inputCount={7}
      titleWidth={"350px"}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {edit ? "Edit Signer" : "Create Signer"}
        </Typography>
        <InputForm
          name="email"
          label="Email"
          placeholder="Email"
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="signerName"
          label="Signer Name"
          placeholder="Signer Name"
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <SelectFormm
          name="prefix"
          label="Prefix"
          defaultValue={0}
          options={optPrefixSelect}
          placeholder="none"
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <InputForm
          name="ktp"
          label="KTP"
          placeholder="KTP"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          type="number"
        />
        <InputForm
          name="phoneNumber"
          label="Phone Number"
          placeholder="Phone Number"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />

        {
          edit &&
          <>
            <InputForm
              name="creationDate"
              label="Creation Date"
              placeholder="Creation Date"
              disabled={true}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <InputForm
              name="signerKeyName"
              label="Signer Key Name"
              placeholder="Signer Key Name"
              disabled={true}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </>
        }

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          {edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          {!edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/signer")}>
            Cancel
          </Button>
        </Stack>

      </Box>
    </DetailDataSkeleton>
  );
};

export default SignerField;
