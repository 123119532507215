import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import PaginationControl from '../pagination/Pagination';
import Swal from 'sweetalert2';
import { useApprovalDeviceMutation } from '../../../store/feature/service/satpasDeviceApiSlice';

const SatpasDeviceTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [approvalDevice, { isSuccess }] = useApprovalDeviceMutation();
  
  const { role_id } = useAppSelector(
    (state: RootState) => state.user
  );

   if (isSuccess) {
    // Swal.fire({
    //   icon: "success",
    //   title: "user registered",
    //   showCancelButton: false,
    //   confirmButtonText: "OK",
    //   confirmButtonColor: "#051438",
    //   text: "Your configuration has been updated",
    // }).then(() => {
    // });
  }

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: DISPATCH DELETE DATA
    const switchStatus = (status: any) => {
      Swal.fire({
        title: `${status} Confirmation`,
        text: `Are you sure you want to ${status} this device?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#808080',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            uid: params.row.id,
            status: status == 'Activate' ? 'ACTIVE' : 'INACTIVE'
          }
          approvalDevice(data)
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button
          disabled={params.row.status == 'ACTIVE'}
          variant="contained"
          onClick={() => switchStatus('Activate')}>
            Active
          </Button>
          <Button
          disabled={params.row.status == 'INACTIVE'}
          variant="contained"
          color="error"
          onClick={() => switchStatus('Deactivate')}>
            Inactive
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'deviceId',
      headerName: 'Device ID',
      flex: 1,
      minWidth: 250,
      headerClassName: 'centered',
    },
    {
      field: 'hostname',
      headerName: 'Hostname',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'enrolledDate',
      headerName: 'Enrolled Date',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        if (params.row.status !== null) {
          switch (params.row.status.toLowerCase()) {
            case 'active': {
              return <Chip label="ACTIVE" color="success" variant="outlined" />;
            }
            case 'inactive': {
              return (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              );
            }
            case 'registered': {
              return (
                <Chip label="REGISTERED" color="primary" variant="outlined" />
              );
            }
          }
        } else {
          return '';
        }
      },
    },
  ];

  if(role_id === 1){
    columns.push(    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    })
  }
  // REGION: INIT ROWS
  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [] 

  const rows = tempData?.map((row: any) => ({ 
    id: row.device_uid,
    registrationId: row.device_registration_id,
    deviceId: row.device_uid,
    hostname: row.device_hostname,
    enrolledDate: row.device_enrolled,
    status: row.device_status,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows !== undefined ? rows : [] }
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default SatpasDeviceTable;
