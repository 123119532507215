import IRoutes from "../interfaces/IRoutes";
import AdminManagement from "../pages/adminManagement/AdminManagement";
import AdminManagementChangePassword from "../pages/adminManagement/AdminManagementChangePassword";
import AdminManagementField from "../pages/adminManagement/AdminManagementField";

const AdminManagementRoute: Array<IRoutes> = [
    {
        element: <AdminManagement />,
        path: "/admin-management",
    },
    {
        element: <AdminManagementField />,
        path: "/admin-management/create",
    },
    {
        element: <AdminManagementField />,
        path: "/admin-management/edit/:id",
    },
    {
        element: <AdminManagementChangePassword />,
        path: "/admin-management/change-password/:id",
    }
];

export { AdminManagementRoute };
