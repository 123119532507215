import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import formatNumberWithCommas from "../../../utils/hash/dotSparator";
import Swal from "sweetalert2";

const CertConfigTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "algorithm",
      headerName: "Algorithm",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "validity",
      headerName: "Validity in days",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        const onUpdate = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate(`/certificate-configuration/edit/${params.row.uid}`);
        };
        return (
          <>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              // justifyContent="center"
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={onUpdate}
                sx={{ width: "100%", height:'50px', lineHeight: '18px',marginY: '20px' }}
              >
                Update
              </Button>
            </Stack>
          </>
        );
      },
    },
  ];
  console.log("new data ==> ", data?.data);
  const tempData: any = data?.data !== undefined ? data?.data : [];

  console.log("tempData ==> ", tempData);

  // REGION: INIT ROWS
  const rows = tempData?.map((row: any) => ({
    id: row.ca_cert_config_new_id,
    uid: row.ca_cert_config_new_uid,
    algorithm: row.ca_cert_config_new_algorithm,
    validity: row.ca_cert_config_new_validity,
    version: row.t_ca_cert_config_new_version,
  }));

  const onDelete = () => {
    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure you want to delete this user admin?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Success Delete Admin",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "Success Delete User Admin",
        });
        // console.log("params.id", params.id)
        // deleteAdminManagement({ id: params.id })
      }
    });
  };

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          getRowHeight={() => "auto"}
        />
      </Box>
      {/* <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      /> */}
    </>
  );
};

export default CertConfigTable;
