import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import BadgeIcon from '@mui/icons-material/Badge';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    icon: <DashboardTwoToneIcon />,
  }, 
  {
    key: 'admin',
    name: 'Admin',
    path: '/admin',
    icon: <ManageAccountsIcon />,
  },
  {
    key: 'signer',
    name: 'Signer',
    path: '/signer',
    icon: <BadgeIcon />,
  },
  {
    key: 'issuer',
    name: 'Issuer',
    path: '/issuer',
    icon: <FolderSharedIcon />,
  },
  {
    key: 'certificate-configuration',
    name: 'Certificate Configuration',
    path: '/certificate-configuration',
    icon: <CardMembershipIcon />,
  },
  {
    key: 'signing-parameter',
    name: 'Signing Parameter',
    path: '/signing-parameter',
    icon: <AssignmentTurnedInIcon />,
  },
  {
    key: 'event-logs',
    name: 'Event Logs',
    path: '/event-logs',
    icon: <TextSnippetIcon />,
  },
  {
    key: 'history-signing',
    name: 'History Signing',
    path: '/history-signing',
    icon: <ContentPasteIcon />,
  },
];
