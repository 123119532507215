import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormLabel, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import {
  useCreateAdminManagementMutation,
  useUpdateAdminManagementMutation,
  useGetRoleListQuery,
  useGetAdminUserDetailMutation,
} from "../../store/feature/service/adminManagementApiSlice";
import Swal from "sweetalert2";
import { adminCreate } from "../../utils/validation/admin";

const PatchingReleaseField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const { pathname, state } = location;
  const edit = pathname.includes("edit");
  const [fileContent, setFileContent] = useState('')
  const { role_id } = useAppSelector((state: RootState) => state.user);
  const [
    createAdminManagement,
    {
      data: createPayload,
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
    },
  ] = useCreateAdminManagementMutation();
  const [
    updateAdminManagement,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateAdminManagementMutation();
  const [
    getAdminUserDetail,
    {
      data: detailPayload,
      isLoading: detailLoading,
      isSuccess: detailSuccess,
      isError: detailError,
    },
  ] = useGetAdminUserDetailMutation();
  const { data: dataRole } = useGetRoleListQuery({});

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const optRoleSelect = dataRole?.data
    ? dataRole?.data?.map((role: any) => ({
        label: role.role_title,
        value: role.role_id,
      }))
    : [];

  const result = optRoleSelect?.map((item: any, index: any) => {
    // Check if it's the first element and add the isDisabled property accordingly
    if (index === 0) {
      return { ...item, isDisabled: true };
    } else {
      return item;
    }
  });

  const isAdminOptSelectRole = role_id === 781373 ? result : optRoleSelect;

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    version: number;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: Number;
    status: string;
    password: string;
    confirmPassword: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminCreate),
    defaultValues: {
      version: 0,
      lastName: "",
      email: "",
      phoneNumber: "",
      role: 0,
      status: "",
      password: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    if (edit) {
      getAdminUserDetail({ id: userUid.id });
    }
  }, []);

  useEffect(() => {
    if (edit && detailSuccess) {
      const {
        usr_firstname,
        usr_lastname,
        usr_email,
        usr_mobile,
        role_id,
        usr_status,
      } = detailPayload.data;
      reset({
        version: usr_firstname,
        lastName: usr_lastname,
        email: usr_email,
        phoneNumber: usr_mobile,
        role: role_id,
        status: usr_status,
      });
    }
  }, [detailSuccess]);

  const handleFileChange = (file: any) => {
    let fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      const content = event.target.result;
      setFileContent(content);
    };
    fileReader.readAsText(file[0]);
  };

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    const { firstName, lastName, email, phoneNumber, role, status } = e;
    const params = {
      role_id: Number(role),
      usr_email: email,
      usr_firstname: firstName,
      usr_lastname: lastName,
      usr_mobile: phoneNumber,
      usr_status: status,
    };
    try {
      if (edit) {
        await updateAdminManagement({ body: params, id: userUid.id });
      } else {
        await createAdminManagement(params);
      }
    } catch (err) {
      console.log("err ===> ", err);
    }
  };

  if (createSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Create Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/admin-management");
    });
  }

  if (createError) {
    Swal.fire({
      icon: "error",
      title: "Error Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Create Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/admin-management");
    });
  }

  if (updateError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={detailLoading}
      isLoadingEvent={createLoading || updateLoading}
      inputCount={7}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {edit
            ? "Patch & Release AI Liveness"
            : "Patch & Release AI Liveness Details"}
        </Typography>

        <InputForm
          name="firstName"
          label="Version"
          placeholder="Type Version Here..."
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <SelectFormm
          name="role"
          label="Patching Type"
          defaultValue={0}
          options={isAdminOptSelectRole}
          placeholder="none"
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <TextAreaForm
          name="lastName"
          label="Description"
          placeholder="Type Description Here..."
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          defaultValue={""}
        />
        <SelectFormm
          name="role"
          label="Minor"
          defaultValue={0}
          options={isAdminOptSelectRole}
          placeholder="none"
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        { !edit ?  <InputForm
          name="functionMenuName"
          label="Path to Certificate Signing Request File"
          errors={errors}
          control={control}
          required
          placeholder="Type Function Menu Name Here....."
          maxLength={100}
          isLoading={false}
          type="file"
          onChange={handleFileChange}
        /> 
        : 
        <>
        <FormLabel sx={{color: 'black'}}>
          Upload Latest Patch 
        </FormLabel>
        <Stack spacing={20} direction='row' sx={{marginY: '10px'}}>

        
        <Typography>
        \OneDrive - PT Autentika\Documents\CA project
        </Typography>
        <Button variant="contained" type="submit">
              Download
            </Button>
            </Stack>
        </>}

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/patching-release")}
          >
            Cancel
          </Button>
          {edit && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )}
          {!edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default PatchingReleaseField;
