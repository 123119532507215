// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const signParamApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSignParam: builder.query<any, any>({
            query: params => {
                return {
                    url: `/signing_param`,
                    params: params
                }
            },
            providesTags:['GetSignParam'],
        }),
        updateSignParam: builder.mutation<any, any>({
            query: ({body, id}) => ({
              url: `/signing_param/${id}`,
              method: "PUT",
              body
            }),
            invalidatesTags: ["UpdateSignParams", 'GetSignParam'],
          }),
  })
})

export const {
useGetSignParamQuery,
useUpdateSignParamMutation
} = signParamApiSlice;