import { Box } from "@mui/system";
import "chart.js/auto";
import {Paper} from "@mui/material";
import DailyStatics from "../../components/molecules/dashboard/DailyStatics";
import HeaderDashboard from "./HeaderDashboard";
import DashboardGrid from "./DashboardGrid";
import { useGetProfileDetailMutation } from "../../store/feature/service/userApiSlice";
import { useEffect } from "react";
import { getToken } from "../../utils/storage";

const Dashboard = () => {

  const token = getToken();
  const [getProfileDetail] = useGetProfileDetailMutation();

  useEffect(()=> {
    if (token !== ''){
    getProfileDetail({})
    }
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <HeaderDashboard />
      {/* <Box
        sx={{
          display: "flex",
          gap: "3rem",
          "& > :not(style)": {
            m: 1,
          },
          mt: 5,
        }}
      >
        <Paper elevation={2} sx={{ p: 4, flex: 1, border: "1px solid #ccc" }}>
          <DailyStatics />
        </Paper>
      </Box>
      <DashboardGrid /> */}
    </Box>
  );
};

export default Dashboard;
