import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AdminSearch from "../../components/molecules/admin/AdminSearch";
import AdminTable from "../../components/molecules/admin/AdminTable";
import { useGetAdminListQuery } from "../../store/feature/service/adminApiSlice";

const Admin = () => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetAdminListQuery(params);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          Admin
        </Typography>
        {/* <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/admin/create")}
            sx={{ marginBottom: "18px" }}>
            <AddIcon /> Create
          </Button>
        </Box> */}
        <AdminSearch params={params} setParams={setParams} />
        <AdminTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default Admin;
