import IRoutes from "../interfaces/IRoutes";
import Signer from "../pages/signer/Signer";
import SignerField from "../pages/signer/SignerField";

const SignerRoute: Array<IRoutes> = [
    {
        element: <Signer />,
        path: "/signer",
    },
    {
        element: <SignerField />,
        path: "/signer/create",
    },
    {
        element: <SignerField />,
        path: "/signer/edit/:id",
    }
];

export { SignerRoute };
