import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import formatNumberWithCommas from "../../../utils/hash/dotSparator";
import Swal from "sweetalert2";
import {
  useDeleteIssuerMutation,
  useDownloadIssuerMutation,
  useSetSignerIssuerMutation,
} from "../../../store/feature/service/issuer";
import { useEffect, useState } from "react";
import downloadFileWithBearerToken from "../../../store/feature/api/downloadFile";

const IssuerTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    setSignerIssuer,
    {
      data: asignPayload,
      isLoading: asignLoading,
      isSuccess: asignSuccess,
      isError: asignError,
    },
  ] = useSetSignerIssuerMutation();

  const [
    deleteIssuer,
    {
      data: deletePayload,
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
    },
  ] = useDeleteIssuerMutation();

  const CheckedComponent = ({ params }: any) => {
    const [itemChecked, setItemChecked] = useState(params.row.signer);
    const onSetSigner = () => {
      if (params.row.signer) {
        Swal.fire({
          icon: "warning",
          title: "Already set as signer",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "Already set as signer",
        });
      } else {
        Swal.fire({
          title: "Set as signer Confirmation",
          text: "Are you sure you want to set this signer user as the main signer?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#808080",
          confirmButtonText: "Yes",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // setItemChecked(!itemChecked)
            setSignerIssuer(params.row.id);
          }
        });
      }
    };
    return (
      <>
        <Checkbox
          defaultChecked={itemChecked}
          onChange={(e) => onSetSigner()}
        />
      </>
    );
  }

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "keyName",
      headerName: "Issuer Key Name",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subjectName",
      headerName: "Issuer Subject Name",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Added date",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        // REGION: NAVIGATE TO EDIT
        const onDownload = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          // downloadIssuer(params.row.id);
          // console.log('wak waw ===> ', params.row)
          downloadFileWithBearerToken({
            downloadUrl: `/signer-data/download?signerId=${params.row.id}&certificateFormat=PEM`,
            fileName: params.row.keyName + ".crt",
          });
        };

        const onDelete = () => {
          Swal.fire({
            title: "Delete Confirmation",
            text: "Are you sure you want to delete this Issuer data?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              // deleteSigner({ id: params.row.userid })
              deleteIssuer({id: params.row.id})
            }
          });
        }

        return (
          <>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              // justifyContent="center"
            >
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  width: "100%",
                  height: "50px",
                  lineHeight: "18px",
                  marginY: "20px",
                }}
                onClick={onDownload}
              >
                Download Crt
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ width: "100%", height: "50px", lineHeight: "18px" }}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Stack>
          </>
        );
      },
    },
    {
      field: "setsigner",
      headerName: "Set as the certificate signer",
      flex: 1.2,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => <CheckedComponent params={params}/>
    },
  ];
  // console.log("new data ==> ", data?.data?.data);
  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [];

  // REGION: INIT ROWS
  const rows = tempData?.map((row: any) => ({
    id: row.ca_signer_id,
    keyName: row.signer_key_name,
    subjectName: row.signer_subject_name,
    date: row.signer_added_date,
    status: row.signer_status,
    signer: row.signer_status.toLowerCase() == "active" ? true : false,
  }));

  const onDelete = () => {
    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure you want to delete this user admin?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Success Delete Admin",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "Success Delete User Admin",
        });
        // console.log("params.id", params.id)
        // deleteAdminManagement({ id: params.id })
      }
    });
  };

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          getRowHeight={() => "auto"}
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default IssuerTable;
