import IRoutes from "../interfaces/IRoutes";
import Issuer from "../pages/issuer/Issuer";
import IssuerField from "../pages/issuer/IssuerField";

const IssuerRoute: Array<IRoutes> = [
    {
        element: <Issuer />,
        path: "/issuer",
    },
    {
        element: <IssuerField />,
        path: "/issuer/create",
    },
    {
        element: <IssuerField />,
        path: "/issuer/edit/:id",
    }
];

export { IssuerRoute };
