import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { adminCreate, adminUpdate } from "../../utils/validation/admin";
import {
  useCreateAdminManagementMutation,
  useUpdateAdminManagementMutation,
  useGetRoleListQuery,
  useGetAdminUserDetailMutation,
} from "../../store/feature/service/adminManagementApiSlice";
import Swal from "sweetalert2";
import { issuer } from "../../utils/validation/issuer";
import { useCreateIssuerMutation } from "../../store/feature/service/issuer";

const IssuerField = () => {
  
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const { pathname, state } = location;
  const edit = pathname.includes("edit");
  const { role_id } = useAppSelector(
    (state: RootState) => state.user
  );
  const [createIssuer, {
    data: createPayload,
    isLoading : createLoading,
    isSuccess: createSuccess,
    isError: createError,
  }] = useCreateIssuerMutation();

  const { data: dataRole } = useGetRoleListQuery({});
  const [fileContent, setFileContent] = useState('');



    const handleFileChange = (file: any) => {
      let fileReader = new FileReader();
      fileReader.onloadend = (event: any) => {
        setFileContent(event.target.result.split(',')[1]);
      };
      fileReader.readAsDataURL(file[0]);
    };

    // const isAdminOptSelectRole = role_id === 781373 ? result : optRoleSelect;

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    keyName: string;
    subjectName: string;
    certificateData: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(issuer),
    defaultValues: {
      keyName: "",
      subjectName: "",
      certificateData: "",
    },
  });


  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    console.log('yg ngontrak rumahnya ===> ', e)
    console.log('yg ngontrak rumahnya uhuy bray ===> ', fileContent.replace(/^data:[a-z]+\/[a-z]+;base64,/, ''))
    const tempData = {
      signer_certificate: fileContent.replace(/^data:[a-z]+\/[a-z]+;base64,/, ''),
      signer_key_name: e.keyName,
      signer_subject_name: e.subjectName
    }
    createIssuer(tempData)

  };

  if (createSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Create Issuer",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Create Issuer",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/issuer");
    });
  }

  if (createError) {
    Swal.fire({
      icon: "error",
      title: "Error Create Issuer",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Create Issuer",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }


  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={detailLoading}
      isLoadingEvent={createLoading}
      inputCount={7}
      titleWidth={"350px"}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Add Issuer
        </Typography>

        <InputForm
          name="keyName"
          label="Issuer Key Name"
          placeholder="Issuer Key Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="subjectName"
          label="Issuer Subject Name"
          placeholder="Issuer Subject Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="certificateData"
          label="Certificate data"
          placeholder="Email"
          // disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={100}
          type="file"
          required
          onChange={handleFileChange}
        />
        

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          {edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          {!edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/issuer")}>
            Cancel
          </Button>
        </Stack>

      </Box>
    </DetailDataSkeleton>
  );
};

export default IssuerField;
