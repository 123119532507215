import { Modal, Box, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from "@mui/x-data-grid";

const DetailModal = ({ open, onClose, detail }: any) => {

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      };
      
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width: "50%", padding: 7, wordBreak: 'break-all' }}>
        <IconButton
          sx={{ position: "absolute", top: 12, right: 12 }}
          onClick={onClose}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography variant="subtitle1">{detail}</Typography>
      </Box>
    </Modal>
  );
};

export default DetailModal;