import IRoutes from "../interfaces/IRoutes";
import SatpasDevice from "../pages/satpasDevice/SatpasDevice";

const SatpasDeviceRoute: Array<IRoutes> = [
  {
    element: <SatpasDevice />,
    path: "/satpas-device",
  }
];

export { SatpasDeviceRoute };
