import { createSlice } from "@reduxjs/toolkit";
import { logResultApiSlice } from "./service/logResultApiSlice";
import { generateRandomString } from "../../utils/randomizedString";

interface initialStateI {
  isLoadingEvent: Boolean;
  keyPage: string;
}

const initialState: initialStateI = {
  isLoadingEvent: false,
  keyPage: "",
};

const logResultSlice = createSlice({
    name: 'logResult',
    initialState,
    reducers: {
      setPageKey: (state, action) => {
        state.keyPage = `${action.payload} ${generateRandomString(10)}`;
      },
    },
    extraReducers(builder) {
      builder
      .addMatcher(
        logResultApiSlice.endpoints.getResultBlob.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        logResultApiSlice.endpoints.getResultBlob.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
        }
      )
      .addMatcher(
        logResultApiSlice.endpoints.getResultBlob.matchRejected,
        state => {
          state.isLoadingEvent = false;
        }
      )
    }
})

export const { setPageKey } = logResultSlice.actions;

export const logResultReducer = logResultSlice.reducer