import { Box, Button, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { useGetRoleListQuery, useUpdateAdminManagementMutation } from '../../store/feature/service/adminManagementApiSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import DetailDataSkeleton from '../../components/template/skeleton/DetailDataSkeleton';
import InputForm from '../../components/molecules/forms/inputForm';
import SelectFormm from '../../components/molecules/forms/selectFormm';
import { useGetProfileDetailMutation } from '../../store/feature/service/userApiSlice';
import { adminCreate } from '../../utils/validation/admin';
import { getToken } from '../../utils/storage';

const Profile = () => {

  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = getToken();
  const [updateAdminManagement, {
    data: updatePayload,
    isLoading : updateLoading,
    isSuccess: updateSuccess,
    isError: updateError,
  }] = useUpdateAdminManagementMutation();
  const [getProfileDetail, {
    data: detailPayload,
    isLoading : detailLoading,
    isSuccess: detailSuccess,
    isError: detailError,
  }] = useGetProfileDetailMutation();
  const { data: dataRole } = useGetRoleListQuery({});

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];
  
  const optRoleSelect = dataRole?.data
    ? dataRole?.data?.map((role: any) => ({
      label: role.role_title,
      value: role.role_id
    }))
    : [];

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: Number;
    status: string;
    password: string;
    confirmPassword: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminCreate),
    defaultValues: {
      uid: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: 0,
      status: "",
      password: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    if(token !== ''){
      getProfileDetail({})
    }
  }, []);

  useEffect(() => {
    if (detailSuccess) {
      const {
        user_uid,
        usr_firstname,
        usr_lastname,
        usr_email,
        usr_mobile,
        role_id,
        usr_status
      } = detailPayload.data
      reset({
        uid: user_uid,
        firstName: usr_firstname,
        lastName: usr_lastname,
        email: usr_email,
        phoneNumber: usr_mobile,
        role: role_id,
        status: usr_status,
      });
    }
  }, [detailSuccess]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    const { uid, firstName, lastName, email, phoneNumber, role, status } = e;
    const params = {
      role_id: Number(role),
      usr_email: email,
      usr_firstname: firstName,
      usr_lastname: lastName,
      usr_mobile: phoneNumber,
      usr_status: status
    }
    try {
      await updateAdminManagement({ body: params, id: uid });
    } catch (err) {
      console.log("err ===> ", err);
    }
  };

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/");
    });
  }

  if (updateError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={detailLoading}
      isLoadingEvent={updateLoading}
      inputCount={7}
      titleWidth={"350px"}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Profile
        </Typography>

        <InputForm
          name="firstName"
          label="Firstname"
          placeholder="Firstname"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="lastName"
          label="Lastname"
          placeholder="Lastname"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="email"
          label="Email"
          placeholder="Email"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="phoneNumber"
          label="Phone Number"
          placeholder="Phone Number"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />
        <SelectFormm
          name="role"
          label="Role"
          defaultValue={0}
          options={optRoleSelect}
          placeholder="none"
          disabled={true}
          isLoading={false}
          control={control}
          errors={errors}
          required
          
        />
        <SelectFormm
          name="status"
          label="Status"
          defaultValue={0}
          options={optStatusSelect}
          placeholder="none"
          disabled={true}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/")}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Stack>

      </Box>
    </DetailDataSkeleton>
  );
}

export default Profile