import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DatePickerForm from "../forms/datePickerForm";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const HistorySigningSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [user, setUser] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [enrolledStart, setEnrolledStart] = useState("");
  const [enrolledEnd, setEnrolledEnd] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    user: string;
    location: string;
    enrolledStart: string,
    enrolledEnd: string,
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      user: "",
      location: "",
      enrolledStart: "",
      enrolledEnd: "",
    },
  });

  const handleUser = (e: string) => { setUser(e); };
  const handleLocationSelect = (e: any) => { setLocation(e); };

  const handleEnrolledStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(enrolledEnd))) {
        setAllDate(NewDate);
      } else {
        setEnrolledStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      user: "",
      location: "",
      enrolledStart: date,
      enrolledEnd: date,
    });
    setEnrolledStart(date);
    setEnrolledEnd(date);
  }
  const handleEnrolledEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setEnrolledEnd(NewDate);
  };
  const conditionCheck = () => {
    if(enrolledStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Enrolled Start"
      });
      reset({
        user: user,
        location: location,
        enrolledStart: "",
        enrolledEnd: "",
      })
      setEnrolledEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(user), [user], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(location), [location], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(date), [date], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(enrolledStart), [enrolledStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(enrolledEnd), [enrolledEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      user_name: user,
      signing_history_location: location,
      dateFrom: enrolledStart,
      dateTo: enrolledEnd,
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="user"
          errors={errors}
          control={control}
          required
          placeholder="Signer Name"
          maxLength={100}
          isLoading={false}
          onChange={handleUser}
        />
        <InputForm
          name="location"
          errors={errors}
          control={control}
          required
          placeholder="Location"
          maxLength={100}
          isLoading={false}
          onChange={handleLocationSelect}
        />
                        <DatePickerForm
          name="enrolledStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Signing"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledStart}
        />
        <DatePickerForm
          name="enrolledEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Signing"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(enrolledStart).getTime())}
          onChange={handleEnrolledEnd}
        />
      </Box>
    </Box>
  );
};

export default HistorySigningSearch;
