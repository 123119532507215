import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import SatpasDeviceSearch from "../../components/molecules/satpasDevice/SatpasDeviceSearch";
import SatpasDeviceTable from "../../components/molecules/satpasDevice/SatpasDeviceTable";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import { useApprovalDeviceMutation, useGetAllSatpasDeviceListQuery } from "../../store/feature/service/satpasDeviceApiSlice";

const SatpasDevice = () => {
  
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetAllSatpasDeviceListQuery(params);
  const { isLoadingEvent } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}>
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          Satpas Device
        </Typography>
        <SatpasDeviceSearch params={params} setParams={setParams} />
        <SatpasDeviceTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default SatpasDevice;
