import { createSlice } from "@reduxjs/toolkit";
import { signerApiSlice } from "./service/signerApiSlice";
import Swal from "sweetalert2";

interface initialStateI {
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  isLoadingEvent: false
};

const signerSlice = createSlice({
    name: 'signer',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      // GENERATE KEY & CERTIFICATE
      .addMatcher(
        signerApiSlice.endpoints.generateUserKeyAndCertificate.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.generateUserKeyAndCertificate.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "success",
            title: "Success Generate Key & Certificate",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Success Generate Key & Certificate",
          })
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.generateUserKeyAndCertificate.matchRejected,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "error",
            title: "Error Generate Key & Certificate",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Error Generate Key & Certificate",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      )

      // REKEY CERTIFICATE
      .addMatcher(
        signerApiSlice.endpoints.rekeyCertificate.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.rekeyCertificate.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "success",
            title: "Success Re-key Certificate",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Success Re-key Certificate",
          })
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.rekeyCertificate.matchRejected,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "error",
            title: "Error Re-key Certificate",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Error Re-key Certificate",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      )

      // RENEW CERTIFICATE
      .addMatcher(
        signerApiSlice.endpoints.renewCertificate.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.renewCertificate.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "success",
            title: "Success Renew Certificate",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Success Renew Certificate",
          })
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.renewCertificate.matchRejected,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "error",
            title: "Error Renew Certificate",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Error Renew Certificate",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      )

      // DELETE SIGNER
      .addMatcher(
        signerApiSlice.endpoints.deleteSigner.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.deleteSigner.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "success",
            title: "Success Delete Signer",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Success Delete Signer",
          });
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.deleteSigner.matchRejected,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "error",
            title: "Error Delete Signer",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Error Delete Signer",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      )

      // SET AS SIGNER
      .addMatcher(
        signerApiSlice.endpoints.setSigner.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.setSigner.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "success",
            title: "Success Set Signer",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Success Set Signer",
          });
        }
      )
      .addMatcher(
        signerApiSlice.endpoints.setSigner.matchRejected,
        state => {
          state.isLoadingEvent = false;
          Swal.fire({
            icon: "error",
            title: "Error Set Signer",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: "Error Set Signer",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      )

    }
})

export const signerReducer = signerSlice.reducer