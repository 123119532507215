import IRoutes from "../interfaces/IRoutes";
import LicenseServer from "../pages/licenseServer/LicenseServer";
import LicenseServerField from "../pages/licenseServer/LicenseServerField";

const LicenseServerRoute: Array<IRoutes> = [
    {
        element: <LicenseServer />,
        path: "/license-server",
    },
    {
        element: <LicenseServerField />,
        path: "/license-server/create",
    }
];

export { LicenseServerRoute };
