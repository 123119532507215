// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const issuerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIssuer: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/signer-data`,
          params: params,
        };
      },
      providesTags: ["GetIssuerList"],
    }),
    createIssuer: builder.mutation<any, any>({
      query: (body) => ({
        url: "/signer-data",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CreateIssuer", "GetIssuerList"],
    }),
    downloadIssuer: builder.mutation<any, any>({
      query: (id) => ({
        url: `/signer-data/download?signerId=${id}&certificateFormat=PEM`,
        method: "GET",
      }),
      invalidatesTags: ["DownloadIssuer"],
    }),
    setSignerIssuer: builder.mutation<any, any>({
      query: (id) => ({
        url: `/signer-data-status/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["SetSignerIssuer", "GetIssuerList"],
    }),
    deleteIssuer: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/signer-data/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ['DeleteIssuer', 'GetIssuerList']
    }),
  }),
});

export const {
  useGetIssuerQuery,
  useCreateIssuerMutation,
  useDownloadIssuerMutation,
  useSetSignerIssuerMutation,
  useDeleteIssuerMutation
} = issuerApiSlice;
