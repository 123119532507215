import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm, TextAreaForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import licenseServer from "../../utils/validation/licenseServer";
import Swal from "sweetalert2";
import { useGenerateLicenseMutation } from "../../store/feature/service/licenseServerApiSlice";
import { removeLicenseString, setLicenseString } from "../../store/feature/licenseReducer";

const LicenseServerField = () => {

  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("edit");
  const [ generateLicense, { isSuccess, isError } ] = useGenerateLicenseMutation()

  if (isSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Generate",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Generate License",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      dispatch(removeLicenseString())
      navigate('/license-server')
    });
  }

  if (isError) {
    Swal.fire({
      icon: "error",
      title: "Error Generate!",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Generate License",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      dispatch(removeLicenseString())
      navigate('/license-server')
    });
  }

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    licenseString: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(licenseServer),
    defaultValues: {
      licenseString: "",
    },
  });


  // REGION: SET FORM DATA
  useEffect(() => {
    if (editFgroup) {
      reset({
        licenseString: "",
      });
    } else {
      reset();
    }
  }, [ ]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    Swal.fire({
      title: `Submit Confirmation`,
      text: `Are you sure you want to submit this license string?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#808080',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLicenseString(e.licenseString))
        generateLicense(e)
      }
    });
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
    isLoading={false}
    isLoadingEvent={false}
    inputCount={6}
    titleWidth={'350px'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        {editFgroup ? "Update License Server" : "Create License Server"}
      </Typography>
      <TextAreaForm
        name="licenseString"
        label="License String"
        defaultValue=""
        placeholder="License String"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        maxLength={1000000}
        required
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/license-server")}>
          Cancel
        </Button>
        { editFgroup &&
          <Button variant="contained" type="submit">
            Update
          </Button>
        }
        { !editFgroup &&
          <Button variant="contained" type="submit">
            Submit
          </Button>
        }
      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default LicenseServerField;
