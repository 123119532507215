import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import AdminManagementSearch from "../../components/molecules/adminManagement/AdminManagementSearch";
import AdminManagementTable from "../../components/molecules/adminManagement/AdminManagementTable";
import { useGetAdminManagementListQuery } from "../../store/feature/service/adminManagementApiSlice";

const AdminManagement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetAdminManagementListQuery(params);
  const { isLoadingEvent } = useAppSelector(
    (state: RootState) => state.adminManagement
  );

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Admin Management
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/admin-management/create")}
            sx={{ marginBottom: "18px" }}
          >
            <AddIcon /> Create
          </Button>
        </Box>
        <AdminManagementSearch params={params} setParams={setParams} />
        <AdminManagementTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default AdminManagement;
