import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useDeleteAdminManagementMutation } from "../../../store/feature/service/adminManagementApiSlice";

const PatchingReleaseTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteAdminManagement, {
    data: deletePayload,
    isLoading : deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
  }] = useDeleteAdminManagementMutation();

  const { role_id } = useAppSelector(
    (state: RootState) => state.user
  );

  if (deleteSuccess) {
    // Swal.fire({
    //   icon: "success",
    //   title: "Success Delete Admin",
    //   showCancelButton: false,
    //   confirmButtonText: "OK",
    //   confirmButtonColor: "#051438",
    //   text: "Success Delete Admin Management",
    // })
  }

  if (deleteError) {
    Swal.fire({
      icon: "error",
      title: "Error Delete Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Delete Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/patching-release/edit/${params.id}`);
    };

    const onChangePassword = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/admin-management/change-password/${params.id}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure you want to delete this user admin?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("params.id", params.id)
          deleteAdminManagement({ id: params.id })
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
      <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onEdit}>
          Details
        </Button>
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
      </>
    );
  };


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      minWidth: 100,
      headerClassName: "centered",
    },
    {
      field: "createdAt",
      headerName: "Release Date",
      minWidth: 250,
    },
    {
      field: "path",
      headerName: "Path",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "checksum",
      headerName: "Checksum",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "typeOfChange",
      headerName: "Type Of Change",
      flex: 1,
      minWidth: 150,
    }
  ];

  console.log('role id here ===> ', role_id)
  if(role_id !== 20682) {
    columns.push({
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    })
  }

  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [] 

  // REGION: INIT ROWS
  const rows = tempData?.map((row: any, index: number) => ({
    id: row.user_uid,
    verison: row.user_id,  
    path: `${row.usr_firstname} ${row.usr_lastname}`,
    typeOfChange: row.usr_email,
    type: row.role_title,
    checksum: row.usr_mobile,
    status: row.usr_status,
    createdAt: row.usr_created_at != null ? row.usr_created_at : 'No Date',
    lastUpdated: row.usr_last_updated != null ? row.usr_last_updated : 'No Date',
    lastLogin: row.usr_last_login != null ? row.usr_last_login : 'No Date',
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows !== undefined ? rows : [] }
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={data?.data?.total_records} />
    </>
  );
};

export default PatchingReleaseTable;
