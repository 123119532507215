import { Box, Button, Chip, CircularProgress, IconButton, Modal, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useGetResultBlobMutation } from "../../../store/feature/service/logResultApiSlice";
import imageNotFound from '../../../assets/pages/not-found.jpg';

const LogResultTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  };

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [ getResultBlob, { data: dataBlob, isLoading, isSuccess } ] = useGetResultBlobMutation();
  const handleClose = () => {
    setOpen(false);
  };

   // REGION: RENDER BUTTON CELL
   const customCellRender = (params: GridCellParams) => {

    // REGION: DISPATCH DELETE DATA
    const showImage = (e: any) => {
      getResultBlob({ id: params.row.id })
      setOpen(true)
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button
          variant="contained"
          onClick={showImage}>
            Show Image
          </Button>
        </Stack>
      </>
    );
  };

  const DetailModal = () => {
    return (
      <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <Box sx={{ ...style, width: "50%", padding: 5 }}>

        <IconButton
          sx={{ position: "absolute", top: 12, right: 12 }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      
        { isLoading && <CircularProgress />}
        { isSuccess && <img src={
            dataBlob?.data?.rst_result
            ? `data:image/jpeg;base64, ${dataBlob?.data?.rst_result}`
            : imageNotFound
          } alt={'image'} style={{ height:'50%', width: '50%' }}/>
        }
        
      </Box>
    </Modal>
    )
  }


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "deviceId",
      headerName: "Device ID",
      flex: 1,
      minWidth: 375,
      headerClassName: "centered",
    },
    {
      field: "date",
      headerName: "Date and time",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "result",
      headerName: "Liveness Result",
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        if( params.row.result !== null ) {
          switch(params.row.result.toLowerCase()) {
            case "success": {
              return <Chip label="SUCCESS" color="success" variant="outlined" />
            }
            case "failed": {
              return <Chip label="FAILED" color="warning" variant="outlined" />
            }
          }
        } else {
          return (
            ""
          )
        }
      },
    },
 
    {
      field: "action",
      headerName: "Image",
      flex: 1,
      minWidth: 320,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [] 

  // REGION: INIT ROWS
  const rows = tempData?.map((row: any, index: any) => ({
    id: row.rst_id,
    deviceId: row.rst_dev_reg_uid != "" ? row.rst_dev_reg_uid : "No Device ID",
    result: row.rst_status,
    date: row.rst_time
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={data?.data?.total_records} />
      <DetailModal />
    </>
  );
};

export default LogResultTable;
