import IRoutes from "../interfaces/IRoutes";
import HistorySigning from "../pages/historySigning/HistorySigning";

const HistorySigningRoute: Array<IRoutes> = [
    {
        element: <HistorySigning />,
        path: "/history-signing",
    },
];

export { HistorySigningRoute };
