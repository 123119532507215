import { createSlice } from "@reduxjs/toolkit";
import { satpasDeviceApiSlice } from "./service/satpasDeviceApiSlice";

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  isLoadingEvent: false
};

const satpasDeviceSlice = createSlice({
    name: 'satpasDevice',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        satpasDeviceApiSlice.endpoints.createSatpasDevice.matchFulfilled,
        state => {
          state.messageSuccess = 'Satpas Device Success Created';
        }
      )
      .addMatcher(
        satpasDeviceApiSlice.endpoints.approvalDevice.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        satpasDeviceApiSlice.endpoints.approvalDevice.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
        }
      )
    }
})


// export const {} = satpasDeviceSlice.actions;
export const satpasDeviceReducer = satpasDeviceSlice.reducer