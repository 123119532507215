import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const SatpasDeviceSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [hostname, setHostname] = useState("");
  const [enrolledStart, setEnrolledStart] = useState("");
  const [enrolledEnd, setEnrolledEnd] = useState("");
  const [userActive, setUserActive] = useState(""); // TODO: NEED PARAMETER FROM API
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    deviceId: string;
    hostname: string;
    enrolledStart: string;
    enrolledEnd: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      deviceId: "",
      hostname: "",
      enrolledStart: "",
      enrolledEnd: "",
      status: "",
    },
  });

  const optSelect = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
    {
      label: "Registered",
      value: "REGISTERED",
    },
  ];

  const handleDeviceId = (e: string) => { setDeviceId(e); };
  const handleHostnameSelect = (e: any) => { setHostname(e); };
  const handleStatusSelect = (e: any) => {
    if(e !== null){
      setUserActive(e.value);
    } else {
      setUserActive("")
    }
    }; 
  const handleEnrolledStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(enrolledEnd))) {
        setAllDate(NewDate);
      } else {
        setEnrolledStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      deviceId: deviceId,
      hostname: hostname,
      enrolledStart: date,
      enrolledEnd: date,
      status: userActive,
    });
    setEnrolledStart(date);
    setEnrolledEnd(date);
  }
  const handleEnrolledEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setEnrolledEnd(NewDate);
  };
  const conditionCheck = () => {
    if(enrolledStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Enrolled Start"
      });
      reset({
        deviceId: deviceId,
        hostname: hostname,
        enrolledStart: "",
        enrolledEnd: "",
        status: userActive,
      });
      setEnrolledEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(deviceId), [deviceId], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(hostname), [hostname], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(userActive), [userActive], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(enrolledStart), [enrolledStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(enrolledEnd), [enrolledEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      dev_reg_uid: deviceId,
      hostname: hostname,
      dev_reg_start_enrolled_time: enrolledStart,
      dev_reg_end_enrolled_time: enrolledEnd,
      dev_reg_status: userActive, 
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="deviceId"
          errors={errors}
          control={control}
          required
          placeholder="Device ID"
          maxLength={100}
          isLoading={false}
          onChange={handleDeviceId}
        />
        <InputForm
          name="hostname"
          errors={errors}
          control={control}
          required
          placeholder="Hostname"
          maxLength={100}
          isLoading={false}
          onChange={handleHostnameSelect}
        />
        <DatePickerForm
          name="enrolledStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Enrolled"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledStart}
        />
        <DatePickerForm
          name="enrolledEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Enrolled"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(enrolledStart).getTime())}
          onChange={handleEnrolledEnd}
        />
        <SelectForm 
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
          isClear
        />
      </Box>
    </Box>
  );
};

export default SatpasDeviceSearch;
