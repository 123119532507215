import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridCloseIcon, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import formatNumberWithCommas from "../../../utils/hash/dotSparator";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useGetEventLogstDetailMutation } from "../../../store/feature/service/eventsLog";

const EventLogsTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState("");

  const [
    getEventLogstDetail,
    {
      data: detailPayload,
      isLoading: detailLoading,
      isSuccess: detailSuccess,
      isError: detailError,
    },
  ] = useGetEventLogstDetailMutation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };

  useEffect(() => {
    if (detailPayload !== undefined) {
      setDetail(detailPayload?.data.evt_description);
      setOpen(true);
    }
  }, [detailPayload]);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const DetailModal = () => {
    return (
      <Modal
        hideBackdrop
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "50%", padding: 5 }}>
          <IconButton
            sx={{ position: "absolute", top: 12, right: 12 }}
            onClick={() => setOpen(false)}
          >
            <GridCloseIcon />
          </IconButton>
          <Typography variant="Subtitle16Bold">{detail}</Typography>
        </Box>
      </Modal>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "module",
      headerName: "Module",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "function",
      headerName: "Function",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "time",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        const onUpdate = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          getEventLogstDetail({ id: params.row.id });
        };
        return (
          <>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              // justifyContent="center"
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={onUpdate}
                sx={{
                  width: "100%",
                  height: "50px",
                  lineHeight: "18px",
                  marginY: "20px",
                }}
              >
                Detail
              </Button>
            </Stack>
          </>
        );
      },
    },
  ];
  console.log("new data ==> ", data?.data?.data);
  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [];

  // REGION: INIT ROWS
  const rows = tempData.map((row: any) => ({
    id: row.event_id,
    module: row.event_module,
    function: row.event_function,
    severity: row.event_severity,
    type: row.event_type,
    time: row.event_time,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          getRowHeight={() => "auto"}
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
      <DetailModal />
    </>
  );
};

export default EventLogsTable;
