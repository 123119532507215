import { createSlice } from "@reduxjs/toolkit";
  
  export interface ISubmitLogin {
    licenseString: string;
  }
  
  const initialState: ISubmitLogin = {
    licenseString: "",
  };

  export const lincenseSlice = createSlice({
    name: "lincense",
    initialState,
    reducers: {
      setLicenseString: (state, actions) => {
        state.licenseString = actions.payload;
      },
      removeLicenseString: (state) => {
        state.licenseString = "";
      }
    }
  });
    
export const { setLicenseString, removeLicenseString } = lincenseSlice.actions;
export const licenseReducer = lincenseSlice.reducer