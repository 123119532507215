import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import { useGetRoleListQuery } from "../../../store/feature/service/adminManagementApiSlice";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const PatchingReleaseSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [firstName, setFirstName] = useState("");
  const [path, setPath] = useState("");
  const [checkSum, setCheckSum] = useState("");
  const [type, setType] = useState("");
  const [createdFrom, setCreatedFrom] = useState("");
  const [createdTo, setCreatedTo] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const dispatch = useAppDispatch();
  const { data: dataRole } = useGetRoleListQuery({});

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    path: string;
    checkSum: string;
    type: string;
    createdFrom: string;
    createdTo: string;
    lastLogin: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      path: "",
      checkSum: "",
      type: "",
      createdFrom: "",
      createdTo: "",
      lastLogin: "",
    },
  });

  const optSelect = dataRole?.data
    ? dataRole?.data?.map((role: any) => ({
        label: role.role_title,
        value: role.role_id,
      }))
    : [];

  const handleFirstName = (e: string) => {
    setFirstName(e);
  };
  const handlePath = (e: any) => {
    setPath(e);
  };
  const handleCheckSum = (e: any) => {
    setCheckSum(e);
  };
  const handleTypeSelect = (e: any) => {
    if (e !== null) {
      setType(e.value);
    } else {
      setType("");
    }
  };
  const handleCreatedFrom = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(createdTo))) {
        setAllDate(NewDate);
      } else {
        setCreatedFrom(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      firstName: firstName,
      path: path,
      checkSum: checkSum,
      type: type,
      createdFrom: date,
      createdTo: date,
      lastLogin: lastLogin,
    });
    setCreatedFrom(date);
    setCreatedTo(date);
  };
  const handleCreatedTo = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setCreatedTo(NewDate);
  };
  const conditionCheck = () => {
    if (createdFrom === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Create Form",
      });
      reset({
        firstName: firstName,
        path: path,
        checkSum: checkSum,
        type: type,
        createdFrom: "",
        createdTo: "",
        lastLogin: lastLogin,
      });
      setCreatedTo("");
      return false;
    } else {
      return true;
    }
  };
  const handleLastLogin = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setLastLogin(NewDate);
  };

  useDebouncedEffect(
    () => setSearchFilterDebounced(firstName),
    [firstName],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(path), [path], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(checkSum), [checkSum], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(type), [type], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdFrom),
    [createdFrom],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdTo),
    [createdTo],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(lastLogin),
    [lastLogin],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      usrFn: firstName,
      usrLn: path,
      usrcheckSum: checkSum,
      usrRoleId: type,
      usrCtdStart: createdFrom,
      usrCtdEnd: createdTo,
      usrLstLoginStart: lastLogin,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="firstName"
          errors={errors}
          control={control}
          required
          placeholder="Firstname"
          maxLength={100}
          isLoading={false}
          onChange={handleFirstName}
        />
        <DatePickerForm
          name="createdFrom"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Release From"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedFrom}
        />
        <DatePickerForm
          name="createdTo"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Release To"
          disabled={false}
          isLoading={false}
          required
          minDate={new Date(createdFrom).getTime()}
          onChange={handleCreatedTo}
        />
        <InputForm
          name="path"
          errors={errors}
          control={control}
          required
          placeholder="Path"
          maxLength={100}
          isLoading={false}
          onChange={handlePath}
        />
        <SelectForm
          name="type"
          defaultValue={1}
          options={optSelect}
          placeholder="Type"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleTypeSelect}
          isClear
        />
        <InputForm
          name="checkSum"
          errors={errors}
          control={control}
          required
          placeholder="CheckSum"
          maxLength={100}
          isLoading={false}
          onChange={handleCheckSum}
        />
        <SelectForm
          name="type"
          defaultValue={1}
          options={optSelect}
          placeholder="Type of Change"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleTypeSelect}
          isClear
        />
      </Box>
    </Box>
  );
};

export default PatchingReleaseSearch;
