import { createSlice } from "@reduxjs/toolkit";
import { adminManagementApiSlice } from "./service/adminManagementApiSlice";

interface initialStateI {
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  isLoadingEvent: false
};

const adminManagementSlice = createSlice({
    name: 'adminManagement',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        adminManagementApiSlice.endpoints.deleteAdminManagement.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        adminManagementApiSlice.endpoints.deleteAdminManagement.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
        }
      )
      .addMatcher(
        adminManagementApiSlice.endpoints.deleteAdminManagement.matchRejected,
        state => {
          state.isLoadingEvent = false;
        }
      )
    }
})

export const adminManagementReducer = adminManagementSlice.reducer