import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import LicenseServerSearch from "../../components/molecules/licenseServer/LicenseServerSearch";
import LicenseServerTable from "../../components/molecules/licenseServer/LicenseServerTable";
import { useGetLicenseServerListQuery } from "../../store/feature/service/licenseServerApiSlice";

const LicenseServer = () => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetLicenseServerListQuery(params);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          License Server
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/license-server/create")}
            sx={{ marginBottom: "18px" }}>
            <AddIcon /> Create
          </Button>
        </Box>
        <LicenseServerTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default LicenseServer;
