import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { adminCreate, adminUpdate } from "../../utils/validation/admin";
import {
  useCreateAdminManagementMutation,
  useUpdateAdminManagementMutation,
  useGetRoleListQuery,
  useGetAdminUserDetailMutation,
} from "../../store/feature/service/adminManagementApiSlice";
import Swal from "sweetalert2";

const EventLogsField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const { pathname, state } = location;
  const edit = pathname.includes("edit");
  const { role_id } = useAppSelector((state: RootState) => state.user);
  const [
    createAdminManagement,
    {
      data: createPayload,
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
    },
  ] = useCreateAdminManagementMutation();
  const [
    updateAdminManagement,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateAdminManagementMutation();
  const [
    getAdminUserDetail,
    {
      data: detailPayload,
      isLoading: detailLoading,
      isSuccess: detailSuccess,
      isError: detailError,
    },
  ] = useGetAdminUserDetailMutation();
  const { data: dataRole } = useGetRoleListQuery({});

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const optRoleSelect = [
    {
      label: "ROLE 1",
      value: "ROLE 1",
    },
    {
      label: "ROLE 2",
      value: "ROLE 2",
    },
  ];

  // const optRoleSelect = dataRole?.data
  //   ? dataRole?.data?.map((role: any) => ({
  //     label: role.role_title,
  //     value: role.role_id
  //   }))
  //   : [];

  const result = optRoleSelect?.map((item: any, index: any) => {
    // Check if it's the first element and add the isDisabled property accordingly
    if (index === 0) {
      return { ...item, isDisabled: true };
    } else {
      return item;
    }
  });

  const isAdminOptSelectRole = role_id === 781373 ? result : optRoleSelect;

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    password: string;
    confirmPassword: string;
    role: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    lastLogin: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(!edit ? adminCreate : adminUpdate),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      role: "",
      status: "",
      createdAt: "",
      updatedAt: "",
      lastLogin: "",
    },
  });

  // useEffect(() => {
  //   if (edit) {
  //     getAdminUserDetail({ id: userUid.id })
  //     reset({
  //       firstName: "Akbar",
  //       lastName: "Sunandar",
  //       email: "akbar@gmail.com",
  //       mobile: "08123456789",
  //       role: "ROLE 1",
  //       status: "ACTIVE",
  //       createdAt: "12 Jan 2023",
  //       updatedAt: "12 Jan 2023",
  //       lastLogin: "12 Jan 2023",
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (edit && detailSuccess) {
  //     const {
  //       usr_firstname,
  //       usr_lastname,
  //       usr_email,
  //       usr_mobile,
  //       role_id,
  //       usr_status
  //     } = detailPayload.data
  //     reset({
  //       firstName: usr_firstname,
  //       lastName: usr_lastname,
  //       email: usr_email,
  //       mobile: usr_mobile,
  //       role: role_id,
  //       status: usr_status,
  //     });
  //   }
  // }, [detailSuccess]);
  const labelAlgorithm = [
    {
      label: 'SHA256withRSA',
      value: 'SHA256withRSA'
    },
    {
      label: 'SHA256',
      value: 'SHA256'
    },
  ]

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    const { firstName, lastName, email, mobile, role, status } = e;
    const params = {
      role_id: Number(role),
      usr_email: email,
      usr_firstname: firstName,
      usr_lastname: lastName,
      usr_mobile: mobile,
      usr_status: status,
    };
    try {
      if (edit) {
        await updateAdminManagement({ body: params, id: userUid.id });
      } else {
        await createAdminManagement(params);
      }
    } catch (err) {
      console.log("err ===> ", err);
    }
  };

  if (createSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Create Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/admin-management");
    });
  }

  if (createError) {
    Swal.fire({
      icon: "error",
      title: "Error Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Create Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/admin-management");
    });
  }

  if (updateError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={detailLoading}
      isLoadingEvent={createLoading || updateLoading}
      inputCount={7}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Update Certificate Config
        </Typography>

        <InputForm
          name="firstName"
          label="Param Name"
          placeholder="Algorithm"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <InputForm
          name="lastName"
          label="Signature Reason"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="email"
          label="Signature Location"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="lastName"
          label="Box x"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="email"
          label="Box y"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="lastName"
          label="Box Width"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="email"
          label="Box Height"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="lastName"
          label="Signature Name"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="email"
          label="Signature Image"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="file"
        />

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          {!edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/certificate-configuration")}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default EventLogsField;
