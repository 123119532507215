import { apiSlice } from "../api/apiSlice";

export const licenseServerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLicenseServerList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/licenses`,
          params: params
        };
      },
      providesTags: ["GetLicenseServerList"],
    }),
    generateLicense: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/license/generate`,
          method: "POST",
        }
      },
      invalidatesTags: ["GenerateLicense", "GetLicenseServerList"],
    }),
  }),
});

export const { useGetLicenseServerListQuery, useGenerateLicenseMutation } = licenseServerApiSlice;
