import * as yup from "yup";
import { validationNotEmpty, validationEmailField } from "./defaultValidations";

export const signerCreate =  yup.object({
  email: validationEmailField("Email"),
  signerName: validationNotEmpty("Signer Name"),
  prefix: validationNotEmpty("Prefix"),
  phoneNumber: validationNotEmpty("Phone Number")
});

export const signerUpdate =  yup.object({
  // email: validationEmailField("Email"),
  // signerName: validationNotEmpty("Signer Name"),
  // prefix: validationNotEmpty("Prefix"),
  phoneNumber: validationNotEmpty("Phone Number")
});