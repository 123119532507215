import { Grid, Paper, Stack, Typography } from "@mui/material";
import { useGetdashboardHeaderQuery } from "../../store/feature/service/dashboardApiSlice";
import dayjs from "dayjs";

const HeaderDashboard = () => {
  const { data } = useGetdashboardHeaderQuery({});
  const totalRequest = data?.data?.request_details;
  const deviceDetails = data?.data?.device_details;
  const currentDate = dayjs();
  const formattedDate = currentDate.format("YYYY MMM DD");

  // console.log("device details ==> ", deviceDetails);
  // console.log("total Request ===> ", totalRequest);
  const totalDetailsRequest = {
    fail_amount: deviceDetails?.inactive_amount,
    fail_percentage: deviceDetails?.inactive_percentage,
    success_amount: deviceDetails?.active_amount,
    success_percentage: deviceDetails?.active_percentage,
    total_request: deviceDetails?.total_devices,
    name: "Device",
  };

  const newTotalRequest = { ...totalRequest, name: "Request" };

  return (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        <Paper
          elevation={2}
          sx={{
            p: 4,
            border: "1px solid #ccc",
            width: "100%",
            height: 270,
            flexWrap: "wrap",
          }}
        >
          <Stack spacing={4} justifyContent={"center"}>
            <Typography variant="h4">Welcome Back,</Typography>
            <Typography variant="h3">{data?.data?.username}</Typography>
            <Typography variant="h4">Today Date: {formattedDate}</Typography>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HeaderDashboard;
