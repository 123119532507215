import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import PaginationControl from '../pagination/Pagination';
import formatNumberWithCommas from '../../../utils/hash/dotSparator';

const LicenseServerTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'uid',
      headerName: 'License UID',
      flex: 2,
      minWidth: 250,
    },
    {
      field: 'capacity',
      headerName: 'License Capacity',
      flex: 2,
      minWidth: 250,
    },
    {
      field: 'added',
      headerName: 'Date Added',
      flex: 2,
      minWidth: 250,
    },
    {
      field: 'ended',
      headerName: 'Date Ended',
      flex: 2,
      minWidth: 250,
    },
  ];
  console.log('new data ==> ', data?.data?.data)
  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [] 
  
  // REGION: INIT ROWS
  const rows = tempData?.map((row: any) => ({
    id: row.license_uid,
    uid: row.license_uid,
    capacity: formatNumberWithCommas(row.license_capacity),
    added: row.license_date_added,
    ended: row.license_date_ended
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default LicenseServerTable;
