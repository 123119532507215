import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import PaginationControl from '../pagination/Pagination';

const HistorySigningTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'user',
      headerName: 'Signer User',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'location',
      headerName: 'Signing Location',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date',
      headerName: 'Signing Date',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);
    const options: any = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    };
  
    return date.toLocaleString('en-US', options);
  };
  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : []

  // REGION: INIT ROWS
  const rows = tempData?.map((row: any, index: string) => ({
      id: index,
      user: row.ds_usr_name,
      location: row.ds_signing_history_location != "" ? row.ds_signing_history_location : "-",
      date: formatDate(row.ds_signing_history_date),
    }
  ));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '575px' }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default HistorySigningTable;
