// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const logEventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEventLogs: builder.query<any, any>({
            query: params => {
                return {
                    url: `/events`,
                    params: params
                }
            },
            providesTags:['GetEventLogs'],
        }),
        getEventLogstDetail: builder.mutation<any, any>({
            query: ({id}) => ({
              url: `/event/${id}`,
              method: "GET",
            }),
            invalidatesTags: ["GetEventLogsDetails"],
          }),
  })
})

export const {
useGetEventLogsQuery,
useGetEventLogstDetailMutation
} = logEventsApiSlice;