import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import { useGetRoleListQuery } from "../../../store/feature/service/adminManagementApiSlice";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminManagementSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState("");
  const [createdFrom, setCreatedFrom] = useState("");
  const [createdTo, setCreatedTo] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const dispatch = useAppDispatch();
  const { data: dataRole } = useGetRoleListQuery({});

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    roles: string;
    createdFrom: string;
    createdTo: string;
    lastLogin: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: "",
      createdFrom: "",
      createdTo: "",
      lastLogin: "",
    },
  });

  const optSelect = dataRole?.data
  ? dataRole?.data?.map((role: any) => ({
    label: role.role_title,
    value: role.role_id
  }))
  : [];

  const handleFirstName = (e: string) => { setFirstName(e); };
  const handleLastNameSelect = (e: any) => { setLastName(e); };
  const handleEmailSelect = (e: any) => { setEmail(e); };
  const handleRolesSelect = (e: any) => {
    if(e !== null){
      setRoles(e.value);
    }else {
      setRoles('');
    } 
    }; 
  const handleCreatedFrom = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(createdTo))) {
        setAllDate(NewDate);
      } else {
        setCreatedFrom(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      firstName: firstName,
      lastName: lastName,
      email: email,
      roles: roles,
      createdFrom: date,
      createdTo: date,
      lastLogin: lastLogin,
    });
    setCreatedFrom(date);
    setCreatedTo(date);
  }
  const handleCreatedTo = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setCreatedTo(NewDate);
  };
  const conditionCheck = () => {
    if(createdFrom === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Create Form"
      });
      reset({
        firstName: firstName,
        lastName: lastName,
        email: email,
        roles: roles,
        createdFrom: "",
        createdTo: "",
        lastLogin: lastLogin,
      });
      setCreatedTo("");
      return false
    } else {
      return true
    }
  }
  const handleLastLogin = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setLastLogin(NewDate);
  };

  useDebouncedEffect(() => setSearchFilterDebounced(firstName), [firstName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(lastName), [lastName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(email), [email], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(roles), [roles], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdFrom), [createdFrom], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdTo), [createdTo], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(lastLogin), [lastLogin], 500); 

  useEffect(() => {
    setParams({
      ...params,
      usrFn: firstName,
      usrLn: lastName,
      usrEmail: email,
      usrRoleId: roles, 
      usrCtdStart: createdFrom,
      usrCtdEnd: createdTo,
      usrLstLoginStart: lastLogin,
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="firstName"
          errors={errors}
          control={control}
          required
          placeholder="Firstname"
          maxLength={100}
          isLoading={false}
          onChange={handleFirstName}
        />
        <InputForm
          name="lastName"
          errors={errors}
          control={control}
          required
          placeholder="Lastname"
          maxLength={100}
          isLoading={false}
          onChange={handleLastNameSelect}
        />
        <InputForm
          name="email"
          errors={errors}
          control={control}
          required
          placeholder="Email"
          maxLength={100}
          isLoading={false}
          onChange={handleEmailSelect}
        />
        <SelectForm 
          name="roles"
          defaultValue={1}
          options={optSelect}
          placeholder="Roles"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleRolesSelect}
          isClear
        />
        <DatePickerForm
          name="createdFrom"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created From"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedFrom}
        />
        <DatePickerForm
          name="createdTo"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created To"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(createdFrom).getTime())}
          onChange={handleCreatedTo}
        />
        <DatePickerForm
          name="lastLogin"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Last Login"
          disabled={false}
          isLoading={false}
          required
          onChange={handleLastLogin}
        />
      </Box>
    </Box>
  );
};

export default AdminManagementSearch;
