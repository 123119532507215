// import { instance, instanceEndUser } from "./service/instance";
import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";

export const adminManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminManagementList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/user/list`,
          params: params,
        };
      },
      providesTags: ["GetAdminManagementList"],
    }),
    getAdminUserDetail: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/user/uid/${id}`,
        method: "GET"
      }),
      invalidatesTags: ['GetAdminUserDetail']
    }),
    createAdminManagement: builder.mutation<any, any>({
      query: (body) => ({
        url: "/user/add",
        method: "POST",
        body
      }),
      invalidatesTags: ['CreateAdminManagement', 'GetAdminManagementList']
    }),
    updateAdminManagement: builder.mutation<any, any>({
      query: ({body, id}) => ({
        url: `/user/update/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['CreateAdminManagement', 'GetAdminManagementList']
    }),
    deleteAdminManagement: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/user/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ['DeleteAdminManagement', 'GetAdminManagementList']
    }),
    getRoleList: builder.query<any, any>({
      query: () => ({
        url: `/role/list`
      }),
      providesTags: ["GetRoleList"],
    }),
    updatePassword: builder.mutation<any, any>({
      query: ({newPassword, id}) => {
        const pasEnc = encrypt(newPassword);
        return {
          url: `/user/change-password/${id}`,
          method: "PUT",
          body: {
            update_password: pasEnc
          }
        }
      },
      invalidatesTags: ['UpdatePassword', 'GetAdminManagementList']
    }),
  }),
});

export const {
  useGetAdminManagementListQuery,
  useCreateAdminManagementMutation,
  useUpdateAdminManagementMutation,
  useGetRoleListQuery,
  useGetAdminUserDetailMutation,
  useDeleteAdminManagementMutation,
  useUpdatePasswordMutation
} = adminManagementApiSlice;
