import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { adminCreate, adminUpdate } from "../../utils/validation/admin";
import {
  useCreateAdminManagementMutation,
  useUpdateAdminManagementMutation,
  useGetRoleListQuery,
  useGetAdminUserDetailMutation,
} from "../../store/feature/service/adminManagementApiSlice";
import Swal from "sweetalert2";
import { signParam } from "../../utils/validation/signParam";
import { useUpdateSignParamMutation } from "../../store/feature/service/signParam";

const SignParameterField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const { pathname } = location;
  const edit = pathname.includes("edit");
  const { role_id } = useAppSelector((state: RootState) => state.user);
  const [
    updateSignParam,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateSignParamMutation();
  const { data: dataRole } = useGetRoleListQuery({});

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const optRoleSelect = [
    {
      label: "ROLE 1",
      value: "ROLE 1",
    },
    {
      label: "ROLE 2",
      value: "ROLE 2",
    },
  ];

  useEffect(()=> {
    console.log('location ==> ', location)
    const {boxX
      ,
      boxY
      ,id
      ,paramHeight
      ,paramLabel
      ,paramWidth
      ,signatureImage
      ,signatureLocation
      ,signatureReason
      } = location.state
    if(location.state !== undefined){
      reset({
        paramName: paramLabel,
      signatureReason,
      signatureLocation,
      boxX,
      boxY,
      boxWidth: paramWidth,
      boxHeight: paramHeight,
      // signatureName:'cosong' ,
      // signatureImage,
      })
    }
  }, [location])
  // const optRoleSelect = dataRole?.data
  //   ? dataRole?.data?.map((role: any) => ({
  //     label: role.role_title,
  //     value: role.role_id
  //   }))
  //   : [];

  const result = optRoleSelect?.map((item: any, index: any) => {
    // Check if it's the first element and add the isDisabled property accordingly
    if (index === 0) {
      return { ...item, isDisabled: true };
    } else {
      return item;
    }
  });

  const isAdminOptSelectRole = role_id === 781373 ? result : optRoleSelect;

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    paramName: string;
    signatureReason: string;
    signatureLocation: string;
    boxX: string;
    boxY: string;
    boxWidth: string;
    boxHeight: string;
    signatureName: string;
    signatureImage: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(signParam),
    defaultValues: {
      paramName: "",
      signatureReason: "",
      signatureLocation: "",
      boxX: "",
      boxY: "",
      boxWidth: "",
      boxHeight: "",
      signatureName: "",
      signatureImage: "",
    },
  });

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    console.log('e ===> ', e)
    const {boxHeight
      ,boxWidth
      ,boxX
      ,boxY
      ,paramName
      ,signatureLocation
      ,signatureName
      ,signatureReason
      } = e
    const tempData = 	{
      box_x: Number(boxX),
      box_y: Number(boxY),
      height: Number(boxHeight),
      signature_location: signatureLocation,
      signature_param_name: paramName,
      signature_reason: signatureReason,
      signer_name: signatureName,
      width: Number(boxWidth)
    }
    updateSignParam({body: tempData, id: userUid.id})
  };

  // if (createSuccess) {
  //   Swal.fire({
  //     icon: "success",
  //     title: "Success Create Admin",
  //     showCancelButton: false,
  //     confirmButtonText: "OK",
  //     confirmButtonColor: "#051438",
  //     text: "Success Create Admin Management",
  //     didOpen: () => Swal.getConfirmButton()?.focus(),
  //   }).then(() => {
  //     navigate("/admin-management");
  //   });
  // }

  // if (createError) {
  //   Swal.fire({
  //     icon: "error",
  //     title: "Error Create Admin",
  //     showCancelButton: false,
  //     confirmButtonText: "OK",
  //     confirmButtonColor: "#051438",
  //     text: "Error Create Admin Management",
  //     didOpen: () => Swal.getConfirmButton()?.focus(),
  //   });
  // }

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/signing-parameter");
    });
  }

  if (updateError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={detailLoading}
      isLoadingEvent={updateLoading}
      inputCount={7}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Update Certificate Config
        </Typography>

        <InputForm
          name="paramName"
          label="Param Name"
          placeholder="Algorithm"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <InputForm
          name="signatureReason"
          label="Signature Reason"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="signatureLocation"
          label="Signature Location"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="boxX"
          label="Box x"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="boxY"
          label="Box y"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="boxWidth"
          label="Box Width"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="boxHeight"
          label="Box Height"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="signatureName"
          label="Signature Name"
          placeholder="Signature Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {/* <InputForm
          name="signatureImage"
          label="Signature Image"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="file"
        /> */}

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          {!edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/signing-parameter")}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default SignParameterField;
