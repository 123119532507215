import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { adminCreate, adminUpdate } from "../../utils/validation/admin";
import Swal from "sweetalert2";
import {
  useCreateAdminMutation,
  useGetAdminDetailMutation,
  useUpdateAdminMutation
} from "../../store/feature/service/adminApiSlice";
import { encrypt } from "../../utils/hash/password";

const AdminField = () => {
  
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const { pathname, state } = location;
  const edit = pathname.includes("edit");
  const { role_id } = useAppSelector((state: RootState) => state.user);
  const [createAdminManagement, {
    data: createPayload,
    isLoading : createLoading,
    isSuccess: createSuccess,
    isError: createError,
  }] = useCreateAdminMutation();
  const [updateAdminManagement, {
    data: updatePayload,
    isLoading : updateLoading,
    isSuccess: updateSuccess,
    isError: updateError,
  }] = useUpdateAdminMutation();
  const [getAdminDetail, {
    data: detailPayload,
    isLoading : detailLoading,
    isSuccess: detailSuccess,
    isError: detailError,
  }] = useGetAdminDetailMutation();

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const optRoleSelect = [
    {
      label: "ADMINISTRATOR",
      value: "1",
    },
    {
      label: "USR_ADM",
      value: "2",
    },
    {
      label: "OPERATOR",
      value: "3",
    },
    {
      label: "AUDITOR",
      value: "4",
    },
    {
      label: "APP_ADM",
      value: "5",
    },
  ];
  
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    password: string;
    confirmPassword: string;
    role: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    lastLogin: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(!edit ? adminCreate : adminUpdate),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      role: "",
      status: "",
      createdAt: "",
      updatedAt: "",
      lastLogin: "",
    },
  });

  useEffect(() => {
    if (edit) {
      getAdminDetail({ id: userUid.id })
      reset({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        role: "",
        status: "",
        createdAt: "",
        updatedAt: "",
        lastLogin: "",
      });
    }
  }, []);

  useEffect(() => {
    console.log("detailPayload", detailPayload)
    if (edit && detailSuccess) {
      const {
        adm_usr_firstname,
        adm_usr_lastname,
        adm_usr_email,
        adm_usr_mobile,
        adm_role_id,
        adm_usr_active,
        adm_usr_createdat,
        adm_usr_updatedat,
        adm_usr_lastlogin
      } = detailPayload.data
      reset({
        firstName: adm_usr_firstname,
        lastName: adm_usr_lastname,
        email: adm_usr_email,
        mobile: adm_usr_mobile,
        role: `${adm_role_id}`,
        status: adm_usr_active.toUpperCase(),
        createdAt: adm_usr_createdat,
        updatedAt: adm_usr_updatedat,
        lastLogin: adm_usr_lastlogin,
      });
    }
  }, [detailSuccess]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    const { firstName, lastName, email, mobile, password, role, status } = e;
    try {
      if (edit) {
        const params = {
          adm_usr_firstname: firstName,
          adm_usr_lastname: lastName,
          adm_usr_mobile: mobile,
          adm_role_id: parseInt(role),
          adm_usr_active: status,
        }
        await updateAdminManagement({ body: params, id: userUid.id });
      } else {
        const params = {
          adm_usr_firstname: firstName,
          adm_usr_lastname: lastName,
          adm_usr_email: email,
          adm_usr_mobile: mobile,
          adm_usr_pwdhash: encrypt(password),
          adm_role_id: parseInt(role),
          adm_usr_active: status,
        }
        await createAdminManagement(params);
      }
    } catch (err) {
      console.log("err ===> ", err);
    }
  };

  if (createSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Create Admin",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/admin");
    });
  }

  if (createError) {
    Swal.fire({
      icon: "error",
      title: "Error Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Create Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Admin",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/admin");
    });
  }

  if (updateError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={detailLoading}
      isLoadingEvent={createLoading || updateLoading}
      inputCount={7}
      titleWidth={"350px"}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {edit ? "Edit Admin" : "Create Admin"}
        </Typography>

        <InputForm
          name="firstName"
          label="Firstname"
          placeholder="Firstname"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="lastName"
          label="Lastname"
          placeholder="Lastname"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="email"
          label="Email"
          placeholder="Email"
          disabled={edit}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="mobile"
          label="Mobile"
          placeholder="Mobile"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />
        {!edit && (
          <>
            <InputForm
              name="password"
              label="Password"
              placeholder="Password"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
              type="password"
            />

            <InputForm
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm Password"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
              type="password"
            />
          </>
        )}
        <SelectFormm
          name="role"
          label="Role"
          defaultValue={0}
          options={optRoleSelect}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <SelectFormm
          name="status"
          label="Status"
          defaultValue={0}
          options={optStatusSelect}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        
        {edit && (
          <>
          <InputForm
            name="createdAt"
            label="Created at"
            placeholder="Created at"
            disabled={true}
            isLoading={false}
            control={control}
            errors={errors}
            maxLength={500}
            required
          />
          <InputForm
            name="updatedAt"
            label="Updated at"
            placeholder="Updated at"
            disabled={true}
            isLoading={false}
            control={control}
            errors={errors}
            maxLength={500}
            required
          />
          <InputForm
            name="lastLogin"
            label="Last Login"
            placeholder="Last Login"
            disabled={true}
            isLoading={false}
            control={control}
            errors={errors}
            maxLength={500}
            required
          />
          </>
        )}

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end">
          {edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          {!edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/admin")}>
            Cancel
          </Button>
        </Stack>

      </Box>
    </DetailDataSkeleton>
  );
};

export default AdminField;
