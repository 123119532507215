import { Box, Button, Chip, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import PaginationControl from '../pagination/Pagination';
import Swal from 'sweetalert2';
import { useDeleteAdminMutation } from '../../../store/feature/service/adminApiSlice';

const AdminTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteAdmin, {
    data: deletePayload,
    isLoading : deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
  }] = useDeleteAdminMutation();

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'User Email',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'roletype',
      headerName: 'Role Type',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'firstname',
      headerName: 'Firstname',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date',
      headerName: 'Created Date',
      flex: 1.2,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            <Grid container spacing={2} mt="5px" mb="20px">
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="gray"
                  onClick={() => navigate(`/admin/edit/${params.row.id}`)}
                  sx={{ width: "100%", height:'50px', lineHeight: '20px' }}>
                  Update
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="gray"
                  onClick={() => navigate(`/admin/change-password/${params.row.id}`)}
                  sx={{ width: "100%", height:'50px', lineHeight: '20px' }}>
                  Change Password
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => { onDelete(params.row.id) }}
                  sx={{ width: "100%", height:'50px', lineHeight: '20px' }}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </>
        );
      },
    },
  ];
  console.log('new data ==> ', data?.data?.data)
  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [] 
  
  const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);
    const options: any = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    };
  
    return date.toLocaleString('en-US', options);
  };

  if (deleteSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Delete Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Delete Admin",
    })
  }

  if (deleteError) {
    Swal.fire({
      icon: "error",
      title: "Error Delete Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Delete Admin",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: INIT ROWS
  const rows = tempData?.map((row: any, index: number) => ({
    id: row.adm_usr_regnum,
    email: row.adm_usr_email,
    roletype: row.adm_role.adm_role_title,
    firstname: row.adm_usr_firstname,
    date: formatDate(row.adm_usr_createdat),
    status: row.adm_usr_active
  }));


  const onDelete = (id: string) => {
    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure you want to delete this user admin?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAdmin({ id: id })
      }
    });
  }

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          getRowHeight={() => "auto"}
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default AdminTable;
