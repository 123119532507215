import IRoutes from "../interfaces/IRoutes";
import PatchingRelease from "../pages/patchingRelease/PatchingRelease";
import PatchingReleaseField from "../pages/patchingRelease/PatchingReleaseField";

const PatchingRealeseRoute: Array<IRoutes> = [
    {
        element: <PatchingRelease />,
        path: "/patching-release",
    },
    {
        element: <PatchingReleaseField />,
        path: "/patching-release/create",
    },
    {
        element: <PatchingReleaseField />,
        path: "/patching-release/edit/:id",
    }
];

export { PatchingRealeseRoute };
