import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/admin-users`,
          params: params
        };
      },
      providesTags: ["GetAdminList"],
    }),
    getAdminDetail: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/admin-user/${id}`,
        method: "GET"
      }),
      invalidatesTags: ['GetAdminDetail']
    }),
    createAdmin: builder.mutation<any, any>({
      query: (body) => ({
        url: "/admin-user",
        method: "POST",
        body
      }),
      invalidatesTags: ['CreateAdmin', 'GetAdminList']
    }),
    updateAdmin: builder.mutation<any, any>({
      query: ({body, id}) => ({
        url: `/admin-user/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdateAdmin', 'GetAdminList']
    }),
    changePasswordAdmin: builder.mutation<any, any>({
      query: ({newPassword, id}) => {
        const pasEnc = encrypt(newPassword);
        return {
          url: `/admin-user-password/${id}`,
          method: "PUT",
          body: {
            update_password: pasEnc
          }
        }
      },
      invalidatesTags: ['ChangePasswordAdmin', 'GetAdminList']
    }),
    deleteAdmin: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/admin-user/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ['DeleteAdmin', 'GetAdminList']
    }),
  }),
});

export const {
  useGetAdminListQuery,
  useGetAdminDetailMutation,
  useCreateAdminMutation,
  useUpdateAdminMutation,
  useChangePasswordAdminMutation,
  useDeleteAdminMutation
} = adminApiSlice;
