import IRoutes from "../interfaces/IRoutes";
import Admin from "../pages/admin/Admin";
import AdminChangePassword from "../pages/admin/AdminChangePassword";
import AdminField from "../pages/admin/AdminField";

const AdminRoute: Array<IRoutes> = [
    {
        element: <Admin />,
        path: "/admin",
    },
    {
        element: <AdminField />,
        path: "/admin/create",
    },
    {
        element: <AdminField />,
        path: "/admin/edit/:id",
    },
    {
        element: <AdminChangePassword />,
        path: "/admin/change-password/:id",
    }
];

export { AdminRoute };
