import IRoutes from "../interfaces/IRoutes";
import LogResult from "../pages/logResult/LogResult";

const LogResultRoute: Array<IRoutes> = [
    {
        element: <LogResult />,
        path: "/log-result",
    }
];

export { LogResultRoute };
