
import CryptoJS from 'crypto-js';

export const encrypt = (data: string) => {
     const key = "UqUu4bqFYJhDcWTCJdOSK4Pb2Bk45Ut7"
     const iv = "L88rOK9UWwWuLF2Q"
    const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    });
  
    return cipher.toString();
  };