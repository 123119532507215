import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const IssuerSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [keyName, setKeyName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [status, setStatus] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    reset,
  } = useForm<{
    keyName: string;
    subjectName: string;
    dateStart: string;
    dateEnd: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      keyName: "",
      subjectName: "",
      dateStart: "",
      dateEnd: "",
      status: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleEmail = (e: string) => {
    setKeyName(e);
  };
  const handleNameSelect = (e: any) => {
    setSubjectName(e);
  };
  const handleStatusSelect = (e: any) => {
    console.log('from here bro ===> ', e)
    if(e === null){
      setStatus("");
    } else {
      setStatus(e.value)
    }
  };
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(dateEnd))) {
        setAllDate(NewDate);
      } else {
        setDateStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      keyName: "",
      subjectName: "",
      dateStart: date,
      dateEnd: date,
      status: "",
    });
    setDateStart(date);
    setDateEnd(date);
  };
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setDateEnd(NewDate);
  };
  const conditionCheck = () => {
    if (dateStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Date",
      });
      reset({
        keyName,
        subjectName,
        dateStart: "",
        dateEnd: "",
        status,
      });
      setDateEnd("");
      return false;
    } else {
      return true;
    }
  };

  useDebouncedEffect(() => setSearchFilterDebounced(keyName), [keyName], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(subjectName),
    [subjectName],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(status), [status], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      signer_key_name: keyName,
      signer_subject_name: subjectName,
      signer_added_date_start: dateStart,
      signer_added_date_end: dateEnd,
      signer_status: status,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="keyName"
          errors={errors}
          control={control}
          required
          placeholder="Key Name"
          maxLength={100}
          isLoading={false}
          onChange={handleEmail}
        />
        <InputForm
          name="subjectName"
          errors={errors}
          control={control}
          required
          placeholder="Subject Name"
          maxLength={100}
          isLoading={false}
          onChange={handleNameSelect}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          minDate={new Date(dateStart).getTime()}
          onChange={handleDateEnd}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
          isClear
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/issuer/create")}
          sx={{ marginBottom: "25px", marginTop: "8px", width: "200px" }}
        >
          <AddIcon /> Add
        </Button>
      </Box>
    </Box>
  );
};

export default IssuerSearch;
