import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { setPageKey } from "../../../store/feature/logResultReducer";

const schema = yup.object({
  title: yup.string().required(),
});

const LogResultSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [result, setResult] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    deviceId: string;
    dateStart: string;
    dateEnd: string;
    result: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      deviceId: "",
      dateStart: "",
      dateEnd: "",
      result: "",
    },
  });

  const optSelect = [
    {
      label: "Success",
      value: "SUCCESS",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ];

  const handleDeviceId = (e: string) => { setDeviceId(e); };
  const handleResultSelect = (e: any) => { setResult(e.value); };
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(dateEnd))) {
        setAllDate(NewDate);
      } else {
        setDateStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      deviceId: deviceId,
      dateStart: date,
      dateEnd: date,
      result: result,
    });
    setDateStart(date);
    setDateEnd(date);
  }
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setDateEnd(NewDate);
  };
  const conditionCheck = () => {
    if(dateStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Date"
      });
      reset({
        deviceId: deviceId,
        dateStart: "",
        dateEnd: "",
        result: result,
      });
      setDateEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(deviceId), [deviceId], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(result), [result], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    console.log("dateStart", dateStart)
    console.log("dateEnd", dateEnd)
    setParams({
      ...params,
      rlogUid: deviceId,
      rlogStatus: result, 
      dateFrom: dateStart,
      dateTo: dateEnd,
    });
    dispatch(setPageKey(deviceId || result || dateStart || dateEnd))
  }, [searchFilterDebounced, dateStart, dateEnd]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="deviceId"
          errors={errors}
          control={control}
          required
          placeholder="Device ID"
          maxLength={100}
          isLoading={false}
          onChange={handleDeviceId}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(dateStart).getTime())}
          onChange={handleDateEnd}
        />
        <SelectForm 
          name="result"
          defaultValue={1}
          options={optSelect}
          placeholder="Result"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleResultSelect}
        />
      </Box>
    </Box>
  );
};

export default LogResultSearch;
