// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const satpasDeviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSatpasDeviceList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/devices`,
          params: params,
        };
      },
      providesTags: ["GetAllSatpasDeviceList"],
    }),
    createSatpasDevice: builder.mutation<any, any>({
      query: (body) => ({
        url: "/device/register",
        method: "POST",
        body
      }),
      invalidatesTags: ["CreateSatpasDeviceList"],
    }),
    approvalDevice: builder.mutation<any, any>({
      query: (params) => {
        const { uid, status } = params
        return {
          url: `/device/approval/${uid}`,
          method: "PUT",
          body: {
            device_status: status
          }
        }
      },
      invalidatesTags: ["ApprovalDevice", "GetAllSatpasDeviceList"],
    }),
  }),
});

export const { useGetAllSatpasDeviceListQuery, useCreateSatpasDeviceMutation, useApprovalDeviceMutation } =
  satpasDeviceApiSlice;
