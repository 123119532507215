import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { adminCreate, adminUpdate } from "../../utils/validation/admin";
import {
  useCreateAdminManagementMutation,
  useUpdateAdminManagementMutation,
  useGetRoleListQuery,
  useGetAdminUserDetailMutation,
} from "../../store/feature/service/adminManagementApiSlice";
import Swal from "sweetalert2";
import { certConfig } from "../../utils/validation/certConfig";
import { useGetCertConfigDetailMutation, useUpdateCertConfigMutation } from "../../store/feature/service/certConfig";

const CertConfigField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const { pathname, state } = location;
  const edit = pathname.includes("edit");
  const { role_id } = useAppSelector((state: RootState) => state.user);
  const [
    getCertConfigDetail,
    {
      data: detailData,
      isLoading: detailLoading,
      isSuccess: detailSuccess,
      isError: detailError,
    },
  ] = useGetCertConfigDetailMutation();
  const [
    updateCertConfig,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateCertConfigMutation();

  useEffect(() => {
    getCertConfigDetail({ id: userUid.id });
  }, []);

  useEffect(() => {
    console.log(
      "detailData  haha ==> ",
      detailData?.data.ca_cert_config_new_validity
    );
    if (detailData !== undefined) {
      const {
        // ca_cert_config_new_id,
        // ca_cert_config_new_uid,
        ca_cert_config_new_algorithm,
        ca_cert_config_new_validity,
        ca_cert_config_new_config_json,
        t_ca_cert_config_new_version,
      } = detailData?.data;
      reset({
        algorithm: ca_cert_config_new_algorithm,
        validity: ca_cert_config_new_validity,
        version: t_ca_cert_config_new_version,
        extensionConfig: ca_cert_config_new_config_json,
      });
    }
  }, [detailData]);

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    algorithm: string;
    validity: string;
    version: string;
    extensionConfig: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(certConfig),
    defaultValues: {
      algorithm: "",
      validity: "",
      version: "",
      extensionConfig: "",
    },
  });

  const labelAlgorithm = [
    {
      label: "SHA256withRSA",
      value: "SHA256withRSA",
    },
    {
      label: "SHA256",
      value: "SHA256",
    },
  ];

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    const { algorithm, extensionConfig, validity, version } = e;
    console.log("e ===> ", e);
    console.log("user id ===> ", userUid.id);
    const tempData = {
      cert_config_algorithm: algorithm,
      cert_config_json: JSON.parse(extensionConfig),
      cert_config_validity: Number(validity),
      cert_config_version: Number(version),
    };
    const tempBody: any = {
      body: tempData,
      id: userUid.id,
    };
    console.log('tempBody === > ', tempBody)
    updateCertConfig(tempBody)
  };

  // if (detailSuccess) {
  //   Swal.fire({
  //     icon: "success",
  //     title: "Success Create Admin",
  //     showCancelButton: false,
  //     confirmButtonText: "OK",
  //     confirmButtonColor: "#051438",
  //     text: "Success Create Admin Management",
  //     didOpen: () => Swal.getConfirmButton()?.focus(),
  //   }).then(() => {
  //     navigate("/admin-management");
  //   });
  // }

  if (detailError) {
    Swal.fire({
      icon: "error",
      title: "Error Create Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Create Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/certificate-configuration");
    });
  }

  if (detailError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Admin Management",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={detailLoading}
      isLoadingEvent={detailLoading}
      inputCount={7}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Update Certificate Config
        </Typography>

        <SelectFormm
          name="algorithm"
          label="Algorithm"
          placeholder="Algorithm"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          options={labelAlgorithm}
          defaultValue={""}
        />
        <InputForm
          name="validity"
          label="Validity in days"
          placeholder="validity in days"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />
        <InputForm
          name="version"
          label="Version"
          placeholder="Version"
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />
        <TextAreaForm
          name="extensionConfig"
          label="Extension Config"
          placeholder="extension config here ...."
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={20000}
          required
          defaultValue={""}
          rows={15}
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          {!edit && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/certificate-configuration")}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default CertConfigField;
