
import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';
import { SatpasDeviceRoute } from './satpasDevice';
import { LicenseServerRoute } from './licenseServer';
import { LogEventsRoute } from './logEvents';
import { AdminManagementRoute } from './adminManagement';
import { LogResultRoute } from './logResult';
import { PatchingRealeseRoute } from './patchingRelease';
import DailyStatics from '../components/molecules/dashboard/DailyStatics';
import ChangePassword from '../pages/profile/ChangePassword';
import { AdminRoute } from './admin';
import { SignerRoute } from './signer';
import { IssuerRoute } from './issuer';
import { CertificateConfigurationRoute } from './certificateConfiguration';
import { SignParameterRoute } from './signParam';
import { HistorySigningRoute } from './historySigning';
import { EventLogsRoute } from './eventLogs';

const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <Login />,
        path: '/login',
      },
      {
        element: <Profile />,
        path: '/profile',
      },
      {
        element: <ChangePassword />,
        path: '/change-password',
      },
      ...SatpasDeviceRoute,
      ...LicenseServerRoute,
      ...LogEventsRoute,
      ...LogResultRoute,
      ...AdminManagementRoute,
      ...PatchingRealeseRoute,

      // LAZIS NU
      ...AdminRoute,
      ...SignerRoute,
      ...IssuerRoute,
      ...CertificateConfigurationRoute,
      ...SignParameterRoute,
      ...HistorySigningRoute,
      ...EventLogsRoute
    ],
  },
];

export default routes;
