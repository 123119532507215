import * as yup from "yup";
import { validationNotEmpty, validationEmailField, validationConfirmNewPassword, validationConfirmNewPasswordL, validationNewPassword } from "./defaultValidations";

export const adminCreate =  yup.object({
  firstName: validationNotEmpty("Firstname"),
  lastName: validationNotEmpty("Lastname"),
  email: validationEmailField("Email"),
  mobile: validationNotEmpty("Mobile"),
  password: validationNewPassword("Password"),
  confirmPassword: validationConfirmNewPasswordL("Confirm Password"),
  role: validationNotEmpty("Role"),
  status: validationNotEmpty("Status")
});

export const adminUpdate =  yup.object({
  firstName: validationNotEmpty("Firstname"),
  lastName: validationNotEmpty("Lastname"),
  mobile: validationNotEmpty("Mobile"),
  role: validationNotEmpty("Role"),
  status: validationNotEmpty("Status")
});