import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import EventLogsTable from "../../components/molecules/eventLogs/EventLogsTable";
import { useGetEventLogsQuery } from "../../store/feature/service/eventsLog";
import EventLogsSearch from "../../components/molecules/eventLogs/EventLogsSearch";

const EventLogs = () => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetEventLogsQuery(params);

  useEffect(()=> {
    console.log('data ====> ', data)
  }, data);
  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          Events Log
        </Typography>
        <EventLogsSearch params={params} setParams={setParams} />
        <EventLogsTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default EventLogs;
