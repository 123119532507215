import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { setPageKey } from "../../../store/feature/logResultReducer";

const schema = yup.object({
  title: yup.string().required(),
});

const LogEventsSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [module, setModule] = useState("");
  const [functionName, setFunctionName] = useState("");
  const [severity, setSeverity] = useState("");
  const [type, setType] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    module: string;
    functionName: string;
    severity: string;
    type: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      module: "",
      functionName: "",
      severity: "",
      type: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Low",
      value: "LOW",
    },
    {
      label: "Medium",
      value: "MEDIUM",
    },
    {
      label: "High",
      value: "HIGH",
    },
  ];

  const handleModule = (e: string) => { setModule(e); };
  const handleFunctionNameSelect = (e: any) => { setFunctionName(e); };
  const handleSeveritySelect = (e: any) => { setSeverity(e.value); }; 
  const handleTypeSelect = (e: string) => { setType(e); };
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(dateEnd))) {
        setAllDate(NewDate);
      } else {
        setDateStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      module: module,
      functionName: functionName,
      severity: severity,
      type: type,
      dateStart: date,
      dateEnd: date
    });
    setDateStart(date);
    setDateEnd(date);
  }
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setDateEnd(NewDate);
  };
  const conditionCheck = () => {
    if(dateStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Date"
      });
      reset({
        module: module,
        functionName: functionName,
        severity: severity,
        type: type,
        dateStart: "",
        dateEnd: "",
      });
      setDateEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(module), [module], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(functionName), [functionName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(severity), [severity], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(type), [type], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      eventModule: module,
      eventFunction: functionName,
      eventType: type,
      eventSeverity: severity, 
      dateFrom: dateStart,
      dateTo: dateEnd,
    });
    dispatch(setPageKey(module || functionName || type || severity || dateStart || dateEnd))
  }, [searchFilterDebounced, dateStart, dateEnd]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="module"
          errors={errors}
          control={control}
          required
          placeholder="Module"
          maxLength={100}
          isLoading={false}
          onChange={handleModule}
        />
        <InputForm
          name="functionName"
          errors={errors}
          control={control}
          required
          placeholder="Function"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionNameSelect}
        />
        <SelectForm 
          name="severity"
          defaultValue={1}
          options={optSelect}
          placeholder="Severity"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleSeveritySelect}
        />
        <InputForm
          name="type"
          errors={errors}
          control={control}
          required
          placeholder="Type"
          maxLength={100}
          isLoading={false}
          onChange={handleTypeSelect}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(dateStart).getTime())}
          onChange={handleDateEnd}
        />
      </Box>
    </Box>
  );
};

export default LogEventsSearch;
