// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const logResultApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLogResultList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/rlog/list`,
                    params: params
                }
            },
            providesTags:['GetLogResultList'],
        }),
        getResultBlob: builder.mutation<any, any>({
          query: (params) => {
            const { id } = params
            return {
              url: `/rlog/resultblob/${id}`,
              method: "GET"
            }
          },
          invalidatesTags: ["GetLogResultBlob"],
        }),
  })
})

export const {
useGetLogResultListQuery,
useGetResultBlobMutation
} = logResultApiSlice;