import IRoutes from "../interfaces/IRoutes";
import CertConfig from "../pages/certificateConfig/CertConfig";
import CertConfigField from "../pages/certificateConfig/CertConfigField";

const CertificateConfigurationRoute: Array<IRoutes> = [
    {
        element: <CertConfig />,
        path: "/certificate-configuration",
    },
    {
        element: <CertConfigField />,
        path: "/certificate-configuration/create",
    },
    {
        element: <CertConfigField />,
        path: "/certificate-configuration/edit/:id",
    }
];

export { CertificateConfigurationRoute };
