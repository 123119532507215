import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import { useGetLicenseServerListQuery } from "../../store/feature/service/licenseServerApiSlice";
import IssuerSearch from "../../components/molecules/issuer/IssuerSearch";
import IssuerTable from "../../components/molecules/issuer/IssuerTable";
import { useGetIssuerQuery } from "../../store/feature/service/issuer";

const Issuer = () => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetIssuerQuery(params);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          Issuer
        </Typography>
        <IssuerSearch params={params} setParams={setParams} />
        <IssuerTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default Issuer;
