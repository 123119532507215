import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import SignerSearch from "../../components/molecules/signer/SignerSearch";
import SignerTable from "../../components/molecules/signer/SignerTable";
import { useGetSignerListQuery } from "../../store/feature/service/signerApiSlice";

const Signer = () => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetSignerListQuery(params);
  const { isLoadingEvent } = useAppSelector((state: RootState) => state.signer);
  
  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          Signer
        </Typography>
        {/* <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/admin/create")}
            sx={{ marginBottom: "18px" }}>
            <AddIcon /> Create
          </Button>
        </Box> */}
        <SignerSearch params={params} setParams={setParams} />
        <SignerTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default Signer;
