import IRoutes from "../interfaces/IRoutes";
import EventLogs from "../pages/eventLogs/EventLogs";
import EventLogsField from "../pages/eventLogs/EventLogsField";

const EventLogsRoute: Array<IRoutes> = [
    {
        element: <EventLogs />,
        path: "/event-logs",
    },
    {
        element: <EventLogsField />,
        path: "/event-logs/create",
    },
    {
        element: <EventLogsField />,
        path: "/event-logs/edit/:id",
    }
];

export { EventLogsRoute };
