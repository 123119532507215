import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [email, setEmail] = useState("");
  const [roletype, setRoletype] = useState("");
  const [firstname, setFirstname] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
  } = useForm<{
    email: string;
    roletype: string;
    firstname: string;
    createdDate: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      roletype: "",
      firstname: "",
      createdDate: "",
      status: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const optSelectRole = [
    {
      label: "ADMINISTRATOR",
      value: "1",
    },
    {
      label: "USR_ADM",
      value: "2",
    },
    {
      label: "OPERATOR",
      value: "3",
    },
    {
      label: "AUDITOR",
      value: "4",
    },
    {
      label: "APP_ADM",
      value: "5",
    },
  ];

  const handleEmail = (e: string) => { setEmail(e); };
  const handleRoletypeSelect = (e: any) => { if(e == null) {setRoletype("")} else {setRoletype(e.value)} };
  const handleFirstnameSelect = (e: any) => { setFirstname(e); };
  const handleStatusSelect = (e: any) => { if(e == null) {setStatus("")} else {setStatus(e.value)} }; 
  const handleCreatedDate = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setCreatedDate(NewDate);
  };

  useDebouncedEffect(() => setSearchFilterDebounced(email), [email], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(roletype), [roletype], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(firstname), [firstname], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(status), [status], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdDate), [createdDate], 500); 

  useEffect(() => {
    setParams({
      ...params,
      adm_usr_email: email,
      adm_role_id: roletype,
      adm_usr_firstname: firstname,
      adm_usr_created_start: createdDate,
      adm_usr_active: status, 
    });
  }, [searchFilterDebounced, roletype, status]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="email"
          errors={errors}
          control={control}
          required
          placeholder="User Email"
          maxLength={100}
          isLoading={false}
          onChange={handleEmail}
        />
        <SelectForm 
          name="roletype"
          defaultValue={1}
          options={optSelectRole}
          placeholder="Role Type"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear={true}
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleRoletypeSelect}
        />  
        <InputForm
          name="firstname"
          errors={errors}
          control={control}
          required
          placeholder="Firstname"
          maxLength={100}
          isLoading={false}
          onChange={handleFirstnameSelect}
        />
        <DatePickerForm
          name="createdDate"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedDate}
        />
        <SelectForm 
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear={true}
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
        <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/admin/create")}
            sx={{ marginBottom: "25px", marginTop: "8px", width: "200px" }}>
            <AddIcon /> Create Admin
          </Button>
      </Box>
    </Box>
  );
};

export default AdminSearch;
