import { createSlice } from "@reduxjs/toolkit";
import { getToken, setToken } from "../../utils/storage";
import { userApiSlice } from "./service/userApiSlice";

export interface ILogin {
    email: string;
    password: string;
    showPass: boolean;
  }
  
  export interface IAccess {
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
    uid: string;
  }
  
  export const initialAccess: IAccess = {
    create: false,
    read: false,
    update: false,
    delete: false,
    uid: ""
  };
  
  export interface ISubmitLogin {
    rejectMessage: string;
    // isLoading: boolean;
    name: string;
    roleTitle: string;
    accesToken: string;
    adminRole: any;
    accessSidebar: any;
    ocspConfig: any;
    role_id: number;
  }
  
  const initialState: ISubmitLogin = {
    rejectMessage: "",
    // isLoading: false,
    name: "",
    roleTitle: "",
    accesToken: getToken(),
    adminRole: {},
    accessSidebar: [],
    ocspConfig: initialAccess,
    role_id: 0
  };

  
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      removeToken: (state) => {
        state.accesToken = "";
        window.location.href = '/login'
      }
    },
    extraReducers(builder) {
      builder.addMatcher(
        userApiSlice.endpoints.submitLogin.matchFulfilled,
        (state, { payload }) => {
          const token = payload.data.access
          state.accesToken = token
          setToken(token);
          console.log('hahaha ===> ', payload.access_token)
        }
      )
      .addMatcher(
        userApiSlice.endpoints.getProfileDetail.matchFulfilled, (state, {payload}) => {
          console.log('profil detail reducer ===> ', payload.data.role_id)
          state.role_id = payload.data.role_id
        }
      )
    }
  });
    
export const { removeToken } = userSlice.actions;

export const userReducer = userSlice.reducer