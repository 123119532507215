import IRoutes from "../interfaces/IRoutes";
import SignParameterField from "../pages/signParameter/SignParameterField";
import SignParameter from "../pages/signParameter/signParameter";

const SignParameterRoute: Array<IRoutes> = [
    {
        element: <SignParameter />,
        path: "/signing-parameter",
    },
    {
        element: <SignParameterField />,
        path: "/signing-parameter/create",
    },
    {
        element: <SignParameterField />,
        path: "/signing-parameter/edit/:id",
    }
];

export { SignParameterRoute };
