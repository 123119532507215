import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import HistorySigningSearch from "../../components/molecules/historySigning/HistorySigningSearch";
import HistorySigningTable from "../../components/molecules/historySigning/HistorySigningTable";
import { useGetHistorySigningQuery } from "../../store/feature/service/historySigningSlice";

const HistorySigning = () => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetHistorySigningQuery(params);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          History Signing
        </Typography>
        <HistorySigningSearch params={params} setParams={setParams} />
        <HistorySigningTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default HistorySigning;
