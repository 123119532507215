import IRoutes from "../interfaces/IRoutes";
import LogEvents from "../pages/logEvents/LogEvents";

const LogEventsRoute: Array<IRoutes> = [
    {
        element: <LogEvents />,
        path: "/log-events",
    }
];

export { LogEventsRoute };
