import { apiSlice } from "../api/apiSlice";

export const historySigningSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHistorySigning: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/signing_histories`,
          params: params
        };
      },
      providesTags: ["GetHistorySigningList"],
    })
  }),
});

export const { useGetHistorySigningQuery } = historySigningSlice;
