import * as yup from "yup";
import { validationNotEmpty, validationEmailField, validationConfirmNewPassword, validationConfirmNewPasswordL, validationNewPassword } from "./defaultValidations";

export const signParam =  yup.object({
  paramName: validationNotEmpty("Param Name"),
      signatureReason: validationNotEmpty("Signature Reason"),
      signatureLocation: validationNotEmpty("Signature Location"),
      boxX: validationNotEmpty("Box X"),
      boxY: validationNotEmpty("Box Y"),
      boxWidth: validationNotEmpty("Box Width"),
      boxHeight: validationNotEmpty("Box Height"),
      // signatureName: validationNotEmpty("Signature Name"),
      // signatureImage: validationNotEmpty("Signature Image"),
});
