// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const certConfigApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCertConfig: builder.query<any, any>({
            query: params => {
                return {
                    url: `/certificate_config`,
                    params: params
                }
            },
            providesTags:['GetCertConfig'],
        }),
        getCertConfigDetail: builder.mutation<any, any>({
            query: ({id}) => {
                return {
                    url: `/certificate_config/${id}`,
                    method: "GET"
                }
            },
            invalidatesTags:['GetCertConfigDetail'],
        }),
        updateCertConfig: builder.mutation<any, any>({
            query: ({body, id}) => ({
              url: `/certificate_config/${id}`,
              method: "PUT",
              body
            }),
            invalidatesTags: ['UpdateCertConfig', 'GetCertConfig']
          }),
  })
})

export const {
useGetCertConfigQuery,
useGetCertConfigDetailMutation,
useUpdateCertConfigMutation
} = certConfigApiSlice;