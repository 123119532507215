import { useState } from "react";
import { getToken } from "../../../utils/storage";

const downloadFileWithBearerToken = async ({
  fileName,
  downloadUrl
}: any) => {
  // const downloadUrl = "/live-file-patch/download"; // Replace with your download URL
  const bearerToken = getToken();
  try {
    // setDownloadLoading(true)
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API}${downloadUrl}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${bearerToken}`, // Add the Bearer token here
          "Cache-Control": "no-cache", // Prevent caching
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to download file: ${response.status} - ${response.statusText}`
      );
    }
    console.log("masuk di fungsi ini ===> ");
    const blob = await response.blob();
    // setBlobData(blob);
    // setDownloadLoading(false)
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file", error);
  }
};

export default downloadFileWithBearerToken;
