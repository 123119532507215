import { Box, Button, Checkbox, Chip, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import PaginationControl from '../pagination/Pagination';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { useDeleteSignerMutation, useGenerateUserKeyAndCertificateMutation, useRekeyCertificateMutation, useRenewCertificateMutation, useSetSignerMutation } from '../../../store/feature/service/signerApiSlice';
import downloadFileWithBearerToken from '../../../store/feature/api/downloadFile';

const SignerTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [generateUserKeyAndCertificate, {
    data: generatePayload,
    isLoading : generateLoading,
    isSuccess: generateSuccess,
    isError: generateError,
  }] = useGenerateUserKeyAndCertificateMutation();
  const [rekeyCertificate, {
    data: rekeyPayload,
    isLoading : rekeyLoading,
    isSuccess: rekeySuccess,
    isError: rekeyError,
  }] = useRekeyCertificateMutation();
  const [renewCertificate, {
    data: renewPayload,
    isLoading : renewLoading,
    isSuccess: renewSuccess,
    isError: renewError,
  }] = useRenewCertificateMutation();
  const [deleteSigner, {
    data: deletePayload,
    isLoading : deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
  }] = useDeleteSignerMutation();
  const [setSigner, {
    data: setSignerPayload,
    isLoading : setSignerLoading,
    isSuccess: setSignerSuccess,
    isError: setSignerError,
  }] = useSetSignerMutation();

  // REGION: INIT COMPONENT
  const StatusComponent = ({ params }: any) => {
    return (
      <>
        {params.row.status !== null ? (
          params.row.status.toLowerCase() === "active" ? (
            <Chip label="ACTIVE" color="success" variant="outlined" />
          ) : (
            <Chip label="INACTIVE" color="warning" variant="outlined" />
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const ActionComponent = ({ params }: any) => {

    const onGenerateKey = () => {
      Swal.fire({
        title: "Generate Key & Certificate Confirmation",
        text: "Are you sure you want to do this action? This will create Key and Certificate for this signer User.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          generateUserKeyAndCertificate(params.row.userid)
        }
      });
    }

    const onRekey = () => {
      Swal.fire({
        title: "Re-key Confirmation",
        text: "Are you sure you want to do this action? This will create a new Key and certifcate and revoke previous key & certificate.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          rekeyCertificate(params.row.userid)
        }
      });
    }

    const onRenew = () => {
      Swal.fire({
        title: "Renew Confirmation",
        text: "Are you sure you want to do this action? This will create a new Certificate and revoke the previous one.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          renewCertificate(params.row.userid)
        }
      });
    }

    const onDownload = () => {
      downloadFileWithBearerToken({downloadUrl: `/docsign-user/download?dsUsrId=${params.row.userid}&certificateFormat=PEM`, fileName: params.row.name+'.crt'})
    }

    const onDelete = () => {
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure you want to delete this signer user data?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteSigner({ id: params.row.userid })
        }
      });
    }
    
    return (
      <>
        <Grid container spacing={2} mt="5px" mb="20px">
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => navigate(`/signer/edit/${params.row.userid}`)}
              sx={{ width: "100%", height:'50px', lineHeight: '18px' }}>
             Update
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => { onGenerateKey() }}
              sx={{ width: "100%", height:'50px', lineHeight: '18px' }}>
             Generate Key & Certificate
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => { onRekey() }}
              sx={{ width: "100%", height:'50px', lineHeight: '18px' }}>
             Re-key
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => { onRenew() }}
              sx={{ width: "100%", height:'50px', lineHeight: '18px' }}>
             Renew Certificate
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => { onDownload() }}
              sx={{ width: "100%", height:'50px', lineHeight: '18px' }}>
             Download Certificate
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="error"
              onClick={() => { onDelete() }}
              sx={{ width: "100%", height:'50px', lineHeight: '18px' }}>
             Delete User
            </Button>
          </Grid>
          
        </Grid>
      </>
    );
  }

  const CheckedComponent = ({ params }: any) => {
    
    const [itemChecked, setItemChecked] = useState(params.row.signer);

    const onSetSigner = () => {
      if(params.row.signer) {
        Swal.fire({
          icon: "warning",
          title: "Already set as signer",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "Already set as signer",
        });
      } else {
        Swal.fire({
          title: "Set as signer Confirmation",
          text: "Are you sure you want to set this signer user as the main signer?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#808080",
          confirmButtonText: "Yes",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // setItemChecked(!itemChecked)
            setSigner(params.row.userid)
          }
        });
      }
    }

    return (
      <>
       <Checkbox
        checked={itemChecked}
        onClick={() => onSetSigner() }/>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Signer Email',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: 'Signer Name',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date',
      headerName: 'Created Date',
      flex: 1.2,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => <StatusComponent params={params}/>
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => <ActionComponent params={params}/>
    },
    {
      field: "setsigner",
      headerName: "Set as the signer",
      flex: 1.2,
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => <CheckedComponent params={params}/>,
    },
  ];

  const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);
    const options: any = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    };
  
    return date.toLocaleString('en-US', options);
  };

  // REGION: INIT DATA
  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [] 
  
  // REGION: INIT ROWS
  const rows = tempData?.map((row: any, index: number) => ({
    id: index,
    userid: row.ds_usr_id,
    email: row.ds_usr_email,
    name: row.ds_usr_name,
    phone: row.ds_usr_phonenumber,
    date: formatDate(row.ds_usr_creationdate),
    status: row.ds_usr_sign_status,
    signer: row.ds_usr_sign_status.toLowerCase() == "active" ? true : false
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          getRowHeight={() => "auto"}
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );

};

export default SignerTable;
