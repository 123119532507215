import { Box, Button, Chip, IconButton, Modal, Typography } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridCloseIcon,
  GridColDef,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import { useState } from "react";
import DetailModal from "../modal/modalDetail";

const LogEventsTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState('')

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };
  const handleCloseModal = ()=> {
    setOpen(false);
  }
  // const DetailModal = () => {
  //   return (
  //     <Modal
  //       hideBackdrop
  //       open={open}
  //       onClose={() => setOpen(false)}
  //       aria-labelledby="child-modal-title"
  //       aria-describedby="child-modal-description"
  //     >
  //       <Box sx={{ ...style, width: "50%", padding: 5 }}>
  //         <IconButton
  //           sx={{ position: "absolute", top: 12, right: 12 }}
  //           onClick={() => setOpen(false)}
  //         >
  //           <GridCloseIcon />
  //         </IconButton>
  //         <Typography variant="Subtitle16Bold">{detail}</Typography>
  //       </Box>
  //     </Modal>
  //   );
  // };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    // {
    //   field: "description",
    //   headerName: "Description",
    //   flex: 1,
    //   minWidth: 375,
    //   headerClassName: "centered",
    // },
    {
      field: "module",
      headerName: "Module",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "function",
      headerName: "Function",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        if (params.row.severity !== null) {
          switch (params.row.severity.toLowerCase()) {
            case "low": {
              return <Chip label="LOW" color="success" variant="outlined" />;
            }
            case "medium": {
              return <Chip label="MEDIUM" color="warning" variant="outlined" />;
            }
            case "high": {
              return <Chip label="HIGH" color="secondary" variant="outlined" />;
            }
          }
        } else {
          return "";
        }
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "deviceId",
      headerName: "Device ID",
      flex: 1,
      minWidth: 250,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const showPopUp = (val: string) => {
          console.log('val ==> ', val)
          setDetail(val)
          setOpen(true);
        };

        return (
          <Button
            variant="contained"
            onClick={()=> showPopUp(params.row.description)}
          >
            Detail
          </Button>
        );
      },
    },
  ];

  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [];

  // REGION: INIT ROWS
  const rows = tempData?.map((row: any, index: number) => ({
    id: index,
    description: row.event_description,
    module: row.event_module,
    function: row.event_function,
    severity: row.event_severity,
    type: row.event_type,
    time: row.event_time,
    deviceId: row.dev_reg_uid != "" ? row.dev_reg_uid : "No Device ID",
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
      <DetailModal open={open} onClose={handleCloseModal} detail={detail} />
    </>
  );
};

export default LogEventsTable;
