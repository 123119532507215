import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const EventLogsSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [module, setModule] = useState("");
  const [functions, setFunctions] = useState("");
  const [severity, setSeverity] = useState("");
  const [enrolledStart, setEnrolledStart] = useState("");
  const [enrolledEnd, setEnrolledEnd] = useState("");
  const [type, setType] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    reset
  } = useForm<{
    module: string;
    functions: string;
    severity: string;
    enrolledStart: string;
    enrolledEnd: string;
    type: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      module: "",
      functions: "",
      severity: "",
      enrolledStart: "",
      enrolledEnd: "",
      type: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleModule = (e: string) => {
    setModule(e);
  };
  const handleFunctions = (e: any) => {
    setFunctions(e);
  };
  const handleSeverity = (e: any) => {
    setSeverity(e);
  };
  const handleType = (e: any) => {
    setType(e);
  };
  const handleEnrolledStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(enrolledEnd))) {
        setAllDate(NewDate);
      } else {
        setEnrolledStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      module: module,
      functions: functions,
      severity: severity,
      enrolledStart: date,
      enrolledEnd: date,
      type: type,
    });
    setEnrolledStart(date);
    setEnrolledEnd(date);
  }
  const handleEnrolledEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setEnrolledEnd(NewDate);
  };
  const conditionCheck = () => {
    if(enrolledStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Enrolled Start"
      });
      reset({
        module: module,
      functions: functions,
      severity: severity,
      enrolledStart: "",
      enrolledEnd: "",
      type: type,
      });
      setEnrolledEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(module), [module], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(functions), [functions], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(severity), [severity], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(type), [type], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(enrolledStart), [enrolledStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(enrolledEnd), [enrolledEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      eventModule: module,
      eventFunction: functions,
      eventSeverity: severity,
      dateFrom: enrolledStart,
      dateTo: enrolledEnd,
      eventType: type,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="module"
          errors={errors}
          control={control}
          required
          placeholder="module"
          maxLength={100}
          isLoading={false}
          onChange={handleModule}
        />
        <InputForm
          name="functions"
          errors={errors}
          control={control}
          required
          placeholder="function"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctions}
        />
        <InputForm
          name="severity"
          errors={errors}
          control={control}
          required
          placeholder="severity"
          maxLength={100}
          isLoading={false}
          onChange={handleSeverity}
        />
         <InputForm
          name="type"
          errors={errors}
          control={control}
          required
          placeholder="type"
          maxLength={100}
          isLoading={false}
          onChange={handleType}
        />
                <DatePickerForm
          name="enrolledStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Enrolled"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledStart}
        />
        <DatePickerForm
          name="enrolledEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Enrolled"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(enrolledStart).getTime())}
          onChange={handleEnrolledEnd}
        />
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/issuer/create")}
          sx={{ marginBottom: "25px", marginTop: "8px", width: "200px" }}
        >
          <AddIcon /> Add
        </Button> */}
      </Box>
    </Box>
  );
};

export default EventLogsSearch;
